import React, {useState,useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Typography, MenuItem, Button } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import useStyles from '../Requisition/styles';
import {HistoryCategory} from './HistoryCategory/HistoryCategory';
import {HistoryTripLogBook} from './HistoryCategory/HistoryTripLogBook';
import {HistoryMaintenance} from './HistoryCategory/HistoryMaintenance';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import { useHistory, Link } from 'react-router-dom';
 

const RefillHistory = () => {

      const classes = useStyles();
      const history = useHistory();
      const [regError, setRegError] = useState(''); 
      const [vehicleRegistration, setVehicleRegistration ] = useState('');
      const [open, setOpen] = React.useState(false);
      const { baseUrlContext, loginDetailsBag }= useContext(CartContext);
      const [loginDetails, setLoginDetails] = loginDetailsBag;
      const baseUrl = baseUrlContext;
      const [addedVehicles, setAddedVehicles] = useState([]);
      const [employers, setEmployers] = useState([]);
      const [selectedEmployer, setSelectedEmployer] = useState('');      
      let availableVehiclesDetail = {};
      let selectedEmployersIdentity = '';
                   



  useEffect(()=> {

      window.scrollTo(0, 0);
      setOpen(!open);
      getEmployers();   

   }, [])


  
  const getEmployers = async()=>{
      
        let nationalID = loginDetailsBag[0].nationalID;
        let data = { nationalID };
     
       const res = axios.post(baseUrl+'getEmployeeFillingTransactions.php',data ).then(function (response) {
      
       setAddedVehicles(response.data); 
       setEmployers(response.data);
       setOpen(false); 

        });

 }  


 const loadVehicles = async()=>{

        let organisationID = loginDetailsBag[0].id;
        let data = { organisationID };
     
       const res = axios.post(baseUrl+'getVehicles.php',data ).then(function (response) {
    
       setOpen(false);
       

       availableVehiclesDetail = response.data;
       setAddedVehicles(response.data); 

        });

 }


const handleEmployerChange  = (event: React.ChangeEvent<HTMLInputElement>) =>{
  
  setSelectedEmployer(event.target.value);
  selectedEmployersIdentity = event.target.value;
    setOpen(!open);

  let data = { selectedCompanyID:selectedEmployersIdentity };

   
   const res = axios.post(baseUrl+'getVehicles.php',data ).then(function (response) {
     
     
       availableVehiclesDetail = response.data;
       setAddedVehicles(response.data);
        setOpen(false);

        });

        

}



const  handleSubmit = async()=>{
        
    
   setOpen(!open);
   let companyID = loginDetailsBag[0].id;  



   let data = { registrationNumber:vehicleRegistration, companyID  };
    
      data = JSON.stringify(data);
         
     const res = await axios.post(baseUrl +'receiveVehicle.php', data )
     .then(function (response) {

      // response.data = dataDec(response.data);
      

      if(response.data.code === '200'){
           
          
           //   loadVehicles();
              alert('added');
              setOpen(false);
      
      }else{

         setRegError(response.data.description);
         setOpen(false);
      }
         
  });
}


  const openTestTable = () => history.push({
  
  pathname:'historytest'

})


  

return (
    <main className = {classes.content}>
            <div className={classes.toolbar}/>

            <Grid container justify="right" direction="column">

            <Grid item container spacing={4} justify="center" >
                     
                  <Grid item xs={12} sm={3} md={4} lg={4}>
                       <Card>

                       {/*<Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>openTestTable()}>
                                      <span className={classes.btnTxt}>
                                          Test Table
                                      </span>    
                        </Button>*/}

                         <CardHeader title="Claims History"  align="center" />
                         <CardContent>
                            <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Date" variant="outlined" fullWidth size="small" select
                                  onChange={handleEmployerChange}
                                  value={selectedEmployer}
                                  > 

                                    {employers.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.companyName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>

                  {addedVehicles.map((vehicleDetails)=> ( 
                      

                    <HistoryCategory  
                        vehicleDetails={vehicleDetails} 
                        vehicleReg={vehicleDetails.registrationNumber} 
                        vehicleNum={vehicleDetails.vehicleNumber}
                       
                        key={vehicleDetails.id}

                    />
                       
                      ))}    

                     <br/>

  
              </CardContent>
            </Card>
        </Grid>
            
        <Grid item xs={12} sm={6} md={4} lg={4}>

                            <Card > 
                         <CardHeader title="Trip Logbook History"  align="center" />                                   
                              <CardContent >

                              <Backdrop className={classes.backdrop} open={open} >
                                  <Loader/>
                              </Backdrop>
<Typography variant="h4" gutterBottom >                
                              <TextField label="Select Date" variant="outlined" fullWidth size="small" select
                                  onChange={handleEmployerChange}
                                  value={selectedEmployer}
                                  > 

                                    {employers.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.companyName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>

                  {addedVehicles.map((vehicleDetails)=> ( 
                      

                    <HistoryTripLogBook 
                        vehicleDetails={vehicleDetails} 
                        vehicleReg={vehicleDetails.registrationNumber} 
                        vehicleNum={vehicleDetails.vehicleNumber}
                       
                        key={vehicleDetails.id}

                    />
                       
                      ))}    

                     <br/>


                        </CardContent>
                    </Card>
                </Grid>
          
                <Grid item xs={12} sm={3} md={4} lg={4}>


                   <Grid container spacing={2}>
                     
                   <Card style={{width:'100%'}}>
                    <CardHeader title="Maintenance History"  align="center" />
                    <CardContent >
                          <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Date" variant="outlined" fullWidth size="small" select
                                  onChange={handleEmployerChange}
                                  value={selectedEmployer}
                                  > 

                                    {employers.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.companyName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>

                  {addedVehicles.map((vehicleDetails)=> ( 
                      

                    <HistoryMaintenance 
                        vehicleDetails={vehicleDetails} 
                        vehicleReg={vehicleDetails.registrationNumber} 
                        vehicleNum={vehicleDetails.vehicleNumber}
                       
                        key={vehicleDetails.id}

                    />
                       
                      ))}    

                     <br/>

                      </CardContent>    
                      </Card>

                   </Grid>  
                </Grid>
            </Grid>
           </Grid>
          </main>
            
      )
}

export default RefillHistory;