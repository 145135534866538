import {Card,  CardContent,  Typography, Grid, Button} from '@material-ui/core';
import useStyles from '../Requisition/styles';	
import { useHistory } from 'react-router-dom';


export const CompleteFuelRefillTransaction = () => {
	
       const classes = useStyles(); 
       const history = useHistory(); 
       const transactionResponseDetailsBag = history.location.transactionState;


  const doneTransacting = () => history.push({ 
      
      pathname: '/dashboard'

});


	return (

      <main className = {classes.content}>
            <div className={classes.toolbar}/>

              <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        
                        <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>  
                            <Card >
                                
                         <CardContent>
                            <Typography align="center" variant="body1" gutterBottom >
                                  <h5>Transaction Status</h5>
                            </Typography><br/>

                 <Typography variant="body1" gutterBottom>                           
                        Status: <b>{transactionResponseDetailsBag.description}</b>
                 </Typography><br/>

                  <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=> doneTransacting()} >
                        <span className={classes.btnTxt}> Done</span>
                   </Button>

                </CardContent>
              </Card>

            </Grid>
          
            <Grid item xs={false} sm={2} md={3} lg={4}/>  
          </Grid>
      </Grid>
    </main>

	);
}

export default CompleteFuelRefillTransaction;