import React, {useState} from 'react';
export const CartContext = React.createContext();
export const CartProvider = (props)=>{

    const [cart, setCart] = useState([]);
    const [cartItem, setCartItem] = useState([]);
    const [totalCartBalance, setTotalCartBalance] = useState(0.00);
    const [loginDetails, setLoginDetails] = useState({});
    const [usersUnits, setUsersUnits] = useState(0.00);
    const [selectedVehicleCategoryID, setSelectedVehicleCategoryID]=useState(0);
    const baseUrlContext = "https://www.digimagesystem.com/LetsMove/api/";
    
    const [userLastInteraction, setUserLastInteraction] = useState('');
    const [selectedMusicCategoryID, setSelectedMusicCategoryID] = useState(0);
    const [selectedLocationID, setSelectedLocationID] = useState(0); 
    const [ quotes, setCurrQuote ] = useState({id:'1',author:'Example', description:'description'});
    
    
    const hashData = function(givenData){
         
         var res = eval(process.env.REACT_APP_INTER_ALGO.split('').reverse().join('')); 

          return res;    
        
       }

 
    
 return (
       <CartContext.Provider value={{

		       cartBag: [cart, setCart],
		       cartItemBag: [cartItem, setCartItem],
           usersUnitsBag: [ usersUnits, setUsersUnits],
	         quotesBag:[quotes, setCurrQuote],

  	       selectedVehicleCategoryBag:[selectedVehicleCategoryID, setSelectedVehicleCategoryID],
           selectedMusicCategoryBag:[selectedMusicCategoryID, setSelectedMusicCategoryID],
	
  	       selectedLocationBag:[selectedLocationID, setSelectedLocationID],
           userLastInteractionBag: [userLastInteraction, setUserLastInteraction],
   
  	       totalCartBalanceBag: [totalCartBalance, setTotalCartBalance],
		       loginDetailsBag : [loginDetails, setLoginDetails],
		       baseUrlContext:baseUrlContext,
		       dataReceiver: hashData

		       }}>
 		{props.children}
 	   </CartContext.Provider>
 	)
}