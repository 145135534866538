import React, {useState, useEffect, useContext} from 'react';
import { Grid, Card, CardContent, Typography, IconButton, Button } from '@material-ui/core';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import AddIcon from '@material-ui/icons/Add';
import {CartContext} from '../../Context/CartContext';
import useStyles from '../Requisition/styles';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';


const Dashboard = () => {
 
     const classes = useStyles();
     const history = useHistory(); 
     const {loginDetailsBag, selectedLocationBag, usersUnitsBag, baseUrlContext}= useContext(CartContext); 
     const [loginDetails, setLoginDetails] = loginDetailsBag;
     const myLoginData = history.location.loginState;
     const [usersUnits, setUsersUnits] = usersUnitsBag;
     const baseUrl = baseUrlContext;


      //PRODUCTION
      let rightsID = loginDetails.rights_id;

     //TESTINNG
     //let userPreviledge = loginDetails.rights_id;  


  if(typeof myLoginData !== 'undefined' && Object.keys(history.location.loginState).length > 0) {
        
          setLoginDetails(history.location.loginState);    
          setUsersUnits(usersUnits);
                    
      }

 useEffect(()=> {

      window.scrollTo(0, 0);

   if(loginDetails.id !== ''){

      let data = { companyID:loginDetails.id}
            
          const res = axios.post(baseUrl+'getCompanyFuelUnits.php',data ).then(function (response) {
             
                setUsersUnits(response.data);
             
        });
      }        
   }, [])



 const goToEnrollVehicle  = () => history.push({
         
      pathname: 'vehicleenrollment'

});     


const goToRequisition = () => history.push({
  
  pathname:'requisition'

})


const goToLogBook = () => history.push({
  
  pathname:'logbook'

})


const goToMaintainance = () => history.push({
  
  //  pathname:'maintenance'

})


const goToFuelRefill = () => history.push({

  pathname:'refillfuel'
  
});


const goToFuelRefillApproval = () => history.push({

  pathname:'refillfuelapproval'
  
});
 

return (
    <main className = {classes.content}>
            
        <div className={classes.toolbar}/>
                          
          <Grid item container spacing={3} >
                
            {rightsID == '5' ? 

            <Grid item container xs={12} sm={12} md={12} lg={12} spacing={2}>
                 
             <Grid item  xs={12} sm={3} md={3} spacing={1}>            

              <Card>          

                <CardContent>

                  <Typography variant="body2" gutterBottom >
                      Click on the 'plus' icon to add vehicles on your profile                            
           	      </Typography>
                </CardContent>
            <div style={{align:'center', borderTop:'1px solid rgba(0, 0, 0, 0.12)'}} onClick={()=>goToEnrollVehicle()} align="center" component={ Link}>
      
                  <IconButton component={ Link } onClick={()=>goToEnrollVehicle()} color="inherit">
                            <AddIcon/>       
                  </IconButton>
            </div>


              </Card>
               <br/>
            <Card>          
               <CardContent>
                 <Typography variant="body2" gutterBottom align='center'>
                    Fuel Refill                              
                 </Typography>
               </CardContent>
                      <div style={{align:'center', borderTop:'1px solid rgba(0, 0, 0, 0.12)'}} onClick={()=>goToFuelRefillApproval()} align="center" component={ Link}>
      
                  <IconButton component={ Link } onClick={()=>goToFuelRefillApproval()} color="inherit">
                            <AddIcon/>       
                  </IconButton>
            </div>


              </Card>
                   </Grid>

                   
                   <Grid item xs={12} sm={4} md={3}>


                       <Accordion className={classes.accordion}>
                          <AccordionSummary expandIcon={<ArrowDropDownOutlinedIcon className={classes.accordionIcon} />} >
                            
                               <span >Vehicle Requisition</span>
                          </AccordionSummary>
                    
                   <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                     As an employee you can make a vehicle requisition for all official or business trips.
                     A vehicle can only be requested once at a time.
                    <br/>
                         Click 'Open' to request a vehicle 
                             
                               <div align='center'>  
                                    <br/>
                                    
                                   <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>goToRequisition()}>
                                        <span className={classes.btnTxt}>Open</span>
                                   </Button>

                                </div> 
                            </Typography>
                                                       
                          </AccordionDetails>

                      </Accordion>

                   </Grid> 


                   <Grid item xs={false} sm={4} md={3} >
                    
                      <Accordion className={classes.accordion}>
                          <AccordionSummary expandIcon={<ArrowDropDownOutlinedIcon className={classes.accordionIcon} />} >
                                           <span >Trip Log Book</span>
                          </AccordionSummary>
                    
                          <AccordionDetails>
                            <Typography variant="body2" gutterBottom> 
                              
                             Record every trip for your vehicle. This should also help process claims against mileage covered while using personal vehicle for business trips.
                              
                              <br/>
                              Click 'Open' to record your current trip.
                               
                               <div align='center'>  
                                    <br/>
                                    
                                     <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>goToLogBook()}>
                                        <span className={classes.btnTxt}>Open</span>
                                     </Button>

                                </div> 
                            </Typography>
                                                       
                          </AccordionDetails>              
                      </Accordion>

                    </Grid>  


                    <Grid item xs={false} sm={4} md={3}>
                         
                      <Accordion className={classes.accordion}>
                          <AccordionSummary expandIcon={<ArrowDropDownOutlinedIcon className={classes.accordionIcon} />} >  
                               <span >Maintainance Log Book</span>
                          </AccordionSummary>
                    
                          <AccordionDetails>
                            <Typography variant="body2" gutterBottom> 
                             Record your vehicle maintenance activities. This should help in tracking the maintenance tasks performed for your vehicle.<br/><br/>

                             
                             <strong>Feature Coming Soon</strong>
                               
                               <div align='center'>  
                                    
                                    
                                     <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>goToMaintainance()}>
                                        <span className={classes.btnTxt}>Open</span>
                                     </Button>

                                </div> 
                            </Typography>
                                                       
                          </AccordionDetails>

                      </Accordion>

                    </Grid>

                  </Grid>

                   :

                
           <Grid item container xs={false} sm={12} md={12} spacing={2}>
                
           <Grid item xs={3} sm={3} md={3} lg={4} spacing={2}/>

              <Grid item xs={6} sm={6} md={6} lg={4} spacing={2}>
              
               <Card>          
                <CardContent>
                  <Typography variant="body2" gutterBottom align='center'>
                     Refill Fuel                             
                  </Typography>
                </CardContent>
                             <div style={{align:'center', borderTop:'1px solid rgba(0, 0, 0, 0.12)'}} onClick={()=>goToFuelRefill()} align="center" component={ Link}>
      
                  <IconButton component={ Link } onClick={()=>goToFuelRefill()} color="inherit">
                            <AddIcon/>       
                  </IconButton>
             </div>

        </Card>

    </Grid>                   
                
               <Grid item xs={3} sm={3} md={3} lg={4} spacing={3}/>                 
                    
                  </Grid>
                 
            }
              </Grid>                
      
  </main>
            

      )
}

export default Dashboard;