import React, {useState,useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Button, Typography, MenuItem } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import useStyles from '../Requisition/styles';
import {VehicleCategory} from './VehicleCategory/VehicleCategory';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import { AlertSuccess } from '../Alert/AlertSuccess';
import { useHistory } from 'react-router-dom';

 

const VehicleEnrollment = () => {



      const classes = useStyles();
      const history = useHistory();
      const [vehicleRegistration, setVehicleRegistration ] = useState('');
      const [open, setOpen] = React.useState(false);
      const { baseUrlContext, loginDetailsBag }= useContext(CartContext);
      const [loginDetails, setLoginDetails] = loginDetailsBag;
      const baseUrl = baseUrlContext;
      const [addedVehicles, setAddedVehicles] = useState([]);
      const [employers, setEmployers] = useState([]);
      const [selectedEmployer, setSelectedEmployer] = useState(''); 
      const [responseMessage, setResponseMessage] = useState('');
      const [success, setSuccess] = useState(false); 
      const [error, setError] = useState(false);     
      let availableVehiclesDetail = {};
      let selectedEmployersIdentity = '';
                   



  useEffect(()=> {

      window.scrollTo(0, 0); 
      setOpen(!open);
      getEmployers();   

   }, [])


  
  const getEmployers = async()=>{

        let nationalID = loginDetailsBag[0].nationalID;
        let data = { nationalID };
     
       const res = axios.post(baseUrl+'getMyEmployers.php',data ).then(function (response) {
     
       setEmployers(response.data);
       setOpen(false); 

        });

 }  


 const loadVehicles = async()=>{

        let organisationID = loginDetailsBag[0].id;
        let data = { organisationID };
     
       const res = axios.post(baseUrl+'getVehicles.php',data ).then(function (response) {
    
       setOpen(false);
       availableVehiclesDetail = response.data;
       setAddedVehicles(response.data); 

        });

 }

 const goToDashboard = () => history.push({
  
  pathname:'dashboard'

})


const handleEmployerChange  = (event: React.ChangeEvent<HTMLInputElement>) =>{
  
    setSelectedEmployer(event.target.value);
    selectedEmployersIdentity = event.target.value;
    setOpen(!open);

  let data = { selectedCompanyID:selectedEmployersIdentity };

   
   const res = axios.post(baseUrl+'getVehicles.php',data ).then(function (response) {
     
     
        availableVehiclesDetail = response.data;
       

         if(response.data !== ''){
           
             setAddedVehicles(response.data);
             setOpen(false);

         
         }else{

             
                setSuccess(true);
                setResponseMessage(response.data);
                setOpen(false);

                setTimeout(() => {

                      setSuccess(!true);
                  
                },9000)

                     
      }





      });

}



const  handleSubmit = async()=>{
        
    
   setOpen(!open);
   let companyID = loginDetailsBag[0].id;  


   // DECLARE YPE/ENCODING TO TRANSFER
   let data = { registrationNumber:vehicleRegistration, companyID  };
    
      data = JSON.stringify(data);
         
     const res = await axios.post(baseUrl +'receiveVehicle.php', data )
     .then(function (response) {

 
      if(response.data.code === '200'){
           
          
          setSuccess(true);
          setResponseMessage(response.data);
          setOpen(false);

          setTimeout(() => {

                setSuccess(!true);
                goToDashboard();
            
          },2000)
              
      
      }else{

       
          setSuccess(true);
          setResponseMessage(response.data);
          setOpen(false);

          setTimeout(() => {

                setSuccess(!true);
            
          },9000)

               
      }
         
  });
}

  

return (
    <main className = {classes.content}>
            <div className={classes.toolbar}/>

            <Grid container justify="right" direction="column">

            <Grid item container spacing={4} justify="center" >
                     
                  <Grid item xs={12} sm={3} md={4} lg={4}> 
                       <Card>
                         <CardContent>
                            <Accordion className={classes.accordion}>
                                   <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                                      <span ><b> Adding Vehicle To a Company? </b></span>
                                   </AccordionSummary>
                                <hr/>
                              <AccordionDetails>
                                <Typography variant="body2" gutterBottom> 
                                  To add a vehicle into your company you have to be added on the companies profile as an employee, from the companys back end system.<br/>
                                                     
                                </Typography>
                                   
                              </AccordionDetails>
                            </Accordion>
  
                        </CardContent>
                      </Card>
                  </Grid>
            
              <Grid item xs={12} sm={6} md={4} lg={4}>

                            <Card > 
                                                             
                                { success  ?
                          
                                    <>
                                      <AlertSuccess messsage={responseMessage.description} code={responseMessage.code}/> 

                                    </> 
                                      :  
                                    <>
                                      {/*<AlertError messsage={responseMessage.description}/>*/} 

                                    </> 
                    
                          }

                                <CardContent >

                              <Backdrop className={classes.backdrop} open={open} >
                                  <Loader/>
                              </Backdrop>

                                <div style={{color:"red", align:"center"}}>{error}</div>
                                  <CardHeader title="Add vehicle"  align="center" />
                                      

                                   
                                  
                                      <Typography variant="h4" gutterBottom>
                                      <TextField label="Registration Number" variant="outlined"  fullWidth size="small"  placeholder="ASD 000 AH"
                                       id="vehicleRegistration"
                                       name="vehicleRegistration"
                                       value={vehicleRegistration}
                                       onChange={(e)=>setVehicleRegistration(e.target.value)}
                                        />
                                      </Typography>  


                                                                          
                               


                                  <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleSubmit()}>
                                       <span className={classes.btnTxt}>
                                          Add 
                                       </span>
                                  </Button>

                                  <br/>

                        </CardContent>

                    </Card>

                                
                </Grid>
          
                <Grid item xs={12} sm={3} md={4} lg={4}>

     
                   <Card style={{width:'100%'}}>
                    <CardHeader title="Company Vehicle"  align="center" />
                    <CardContent >
                          <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Employer" variant="outlined" fullWidth size="small" select
                                  onChange={handleEmployerChange}
                                  value={selectedEmployer}
                                  > 

                                    {employers.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.companyName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>

                  {addedVehicles.map((vehicleDetails)=> ( 
                      

                    <VehicleCategory 
                        vehicleDetails={vehicleDetails} 
                        vehicleReg={vehicleDetails.registrationNumber} 
                        vehicleNum={vehicleDetails.vehicleNumber}
                       
                        key={vehicleDetails.id}

                    />
                       
                      ))}    

                     <br/>

                      </CardContent>    
                      </Card>

                     



                   
                </Grid>

            </Grid>
           </Grid>
          </main>
            
      )
}

export default VehicleEnrollment;