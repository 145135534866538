import React from 'react';
import useStyles from '../Requisition/styles';



const Footer = () => {

const classes = useStyles();
let fullYear = new Date().getFullYear();


return (
 < >

        <div className = {classes.footer} align='center'>            
             <strong style={{ fontWeight: 'normal'}}>
                  
                   Powered by: Digimage © {fullYear} |
                              <a href='https://www.salvtec.co.sz/LetsMovePrivacy.htlm' style={{color:'#BB7E2C', backgroungColor:'#BB7E2C'}}>

                          <span style={{color:'white'}}> Privacy Statement</span></a>
             </strong>
        </div>

  </>

   )
}

export default Footer;