import React, {useState, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Button, Typography } from '@material-ui/core';
import { CartContext } from '../../Context/CartContext';
import useStyles from '../Requisition/styles'; 
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';




      const UserProfile = () => {


      const classes = useStyles();
      const history = useHistory();
      const {loginDetailsBag, dataReceiver, baseUrlContext }= useContext(CartContext); 
      const [loginDetails, setLoginDetails] = loginDetailsBag;
      const baseUrl = baseUrlContext;
      const [loginError, setLoginError] = useState('');
      const [open, setOpen] = React.useState(false);
      const dataDec  = dataReceiver;
      let fullName = '';
      let username = '';
      let loading = ''




  if(typeof myLoginData !== 'undefined' && Object.keys(history.location.loginState).length > 0) {
        
          setLoginDetails(history.location.loginState);  
                    
  }



 const goToHomePage = () => history.push({
         
      pathname: '/dashboard'

});



const hitLoginApi = async()=>{
            
        setOpen(!open);
       
        let data = { username: username, password: username }
        data = JSON.stringify(data);
         
   const res = await axios.post(baseUrl +'login.php',data)
  .then(function (response) {

     //   response.data = dataDec(response.data);
      
     if(response.data.code === '200'){
      
             setLoginDetails(response.data);     
             goToHomePage();
             setOpen(false);

         }else{

             setLoginError(response.data.error);
             setOpen(false);

        }      
     });       
  }
   

const  handleSubmit = () => {
  
    if(username === '' || fullName === ''){

      setLoginError('Your username and password can not be empty, please correct and try again');

    }else{

        hitLoginApi();
        setLoginError('');
 
    }
}


const handleRegister = () => history.push({
      
     pathname: '/registration'
    
});




const handleProfileUpdate = () => {
  

}


return (
    <main className = {classes.content}>
            <div className={classes.toolbar}/>
                  
             <Grid container direction="column">

                <Grid item></Grid>

                <Grid item container spacing={4}>
                  <Grid item xs={false} sm={3} md={3} lg={4}/>
            
                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Card >
                                
                          <CardContent>
                               <div style={{color:"red", align:"center"}}>{loginError}</div>
                               <CardHeader title="Users Profile"  align="center" />
                                    
                                  <Typography variant="body2" gutterBottom>
                                      <b>Company Number</b>: {loginDetails.companyNumber}
                                  </Typography>
                                      <br/>

                                  <Typography variant="h4" gutterBottom>
                                    <TextField label="Username" variant="outlined"  fullWidth size="small"
                                    id="username"
                                    name="username"
                                    value={loginDetails.email}
                                    />
                                  </Typography>

                                  <Typography variant="h4" gutterBottom>
                                      <TextField label="Full Name" variant="outlined"  fullWidth size="small"  
                                      type="text"
                                      id="email"
                                      name="email"
                                      value={loginDetails.fullName}
                                    />
                                  </Typography>


                                  <Typography variant="h4" gutterBottom>
                                      <TextField label="Cell Number" variant="outlined"  fullWidth size="small"  
                                      type="text"
                                      id="cellNumber"
                                      name="cellNumber"
                                      value={loginDetails.code}
                                    />
                                  </Typography>


                                  <Typography variant="h4" gutterBottom>
                                      <TextField label="New Password" variant="outlined"  fullWidth size="small"  
                                      type="text"
                                      id="newPassword"
                                      name="newPassword"
                                    />
                                  </Typography>


                          <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                          </Backdrop>            

                         <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleProfileUpdate()}>
                               <span className={classes.btnTxt}>Update Profile</span>
                          </Button>


                         {/*
                         <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleSubmit()}>
                               <span className={classes.btnTxt}>Switch User</span>
                          </Button>

                         <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleRegister()}>
                                <span className={classes.btnTxt}>Log Into Another Account</span>
                         </Button>
                         */}                   
     
                    </CardContent>
                  </Card>
              </Grid>
          
                 <Grid item xs={false} sm={3} md={4} lg={4}/>
                 </Grid>

              </Grid>

     </main>
            
      )
}

export default UserProfile;