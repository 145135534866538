import React from 'react';
import { Grid} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import useStyles from '../../Requisition/styles';	
import { useHistory } from 'react-router-dom';
   
   
export const HistoryMaintenance = ({vehicleDetails,vehicleReg, vehicleNum}) => {

 const classes = useStyles(); 


	return (

    <>
     
     <Grid item xs={12} sm={12}>
             
                                
                    
               <Accordion className={classes.accordion}>
                          <AccordionSummary expandIcon={<ArrowDropDownOutlinedIcon className={classes.accordionIcon} />} >
                            
                             
                              Transaction # :<span>{vehicleReg}</span>
                          </AccordionSummary>
                    
                          <AccordionDetails >
                           <span style={{borderTop: '1px solid rgba(0, 0, 0, 0.12)', width:'100%'}}> 
                            <p >
                                   
                                  
                                  <b>Vehicle :</b>{vehicleReg}

                                  <br/>

                                  <b>Service Centre :</b>{vehicleReg}

                                  <br/>

                                  <b> Next service :</b>{vehicleReg}

                                  <br/>

                                  <b>Service Details :</b>{vehicleReg}

                                   <br/>

                                  <b>Amount :</b>{vehicleReg}

                                  



                                   
                                </p>
                              </span> 
                                                          
                          </AccordionDetails>
              
                      </Accordion>  

               </Grid>
   
 </> 

	);
}

export default HistoryMaintenance;