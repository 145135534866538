import React, {useContext, useState, useEffect} from 'react';
import { AppBar, Toolbar, IconButton,  Badge,  Typography} from '@material-ui/core';
import { ShoppingCart, Menu } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import GarageIcon from '@mui/icons-material/Garage';
import HomeIcon from '@mui/icons-material/Home';
import useStyles from './styles';
import {CartContext} from '../../Context/CartContext';
import { Link, useLocation } from 'react-router-dom';
import Drawer from '../../components/Drawer/Drawer';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import CarRepairIcon from '@mui/icons-material/CarRepair';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';

function goToTop(){ 

   window.scrollTo(0, 0); 

}

const NavBar = () => {

     const classes = useStyles();
     const history = useHistory();
     const location = useLocation();
     const [ deviceDimentionW, setDeviceDimentionW] = useState('');
     const { baseURL, cartBag, loginDetailsBag, dataReceiver, selectedLocationBag, userLastInteractionBag, selectedVehicleCategoryBag } = useContext(CartContext);    
     const dataDec = dataReceiver;
     const [cart, setCart] = cartBag;
     const [selectedVehicleCategoryID, setSelectedVehicleCategoryID] = selectedVehicleCategoryBag;
     const [selectedLocationID, setSelectedLocationID] = selectedLocationBag;
     const [userLastInteraction, setUserLastInteraction] = userLastInteractionBag;
     const [open, setOpen] = useState(false);   
     const [loginDetails, setLoginDetails] = loginDetailsBag;
     let enquiryResponseBag = {}; 
     const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());   
     let screenWidth = windowDimensions.width;
     let userName = loginDetailsBag[0].username;
     let rightsID = loginDetails.rights_id; 



    function getWindowDimensions() {
      
       const { innerWidth: width, innerHeight: height } = window;
                return {
                     width,
                     height
                 };
              
              }
                                 


    const handleDrawer = () => {
        
        setOpen(true);

     }


useEffect(() => {
    
  function handleResize() {

      setWindowDimensions(getWindowDimensions());
      
     }


    setDeviceDimentionW(windowDimensions.width);

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);

         goToTop();
         window.scrollTo(0, 0);   

   }, []);

  
  const goToEnquiryEmailStatus = () => history.push({
    
        pathname: '/enquiryresponsestatus',
        enquiryResponseState:enquiryResponseBag

});


// SPECIFY THE PARAMS WHICH WIL DETERMINE DATA TO BE RETRIEVED (if getting vehicles in transit send param id 1)


const openStandbyVehicles = () => history.push({
    
    pathname:'/vehiclelist'
  
});


  const goToSelectedVehiclesGategory = () => history.push({
    
        pathname: '/vehiclelist'

});


  const openInTransitVehicles = () => history.push({
    
        pathname: '/vehiclelist'

});
  

const openVehicles  = () =>{
         
    goToSelectedVehiclesGategory();

}



const openAvailableVehicle  = () =>{
   
      setSelectedVehicleCategoryID('vehicleOpen');
     
}




const requestEmailedEnquiryDetails = async()=>{
        
     const data = {data:''};

    const res = await axios.post(baseURL+'/bridgeSelfEnquiryPdf.php', data )
            .then(function (response) {

            response.data = dataDec(response.data);

            enquiryResponseBag = response.data;
           
            goToEnquiryEmailStatus();

           setOpen(false);

       });
            
   }


 const logOut = () =>{

  setLoginDetails({});

 }

    return (
        <>
          <AppBar position="fixed" className={classes.appBar} style={{backgroundColor:'#BB7E2C'}}>
            <Toolbar >
                
              { location.pathname ==='/dashboard' && (
                <div className={classes.btnCard}>
                      <IconButton color="inherit" edge="start" aria-label="menu" onClick={handleDrawer}>
                                  <Menu />
                      </IconButton>
                </div>)} 


              { location.pathname ==='/vehicleenrollment' &&  (
                <div className={classes.btnCard}>

                    <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>

                </div>)}


              { location.pathname ==='/registration' &&  (
                <div className={classes.btnCard}>

                    <IconButton component={ Link } to="/" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}


               { location.pathname ==='/passwordreset' &&  (
                <div className={classes.btnCard}>

                    <IconButton component={ Link } to="/" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

              { location.pathname ==='/requisition' &&  (
                <div className={classes.btnCard}>

                    <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

               { location.pathname ==='/logbook' &&  (
                <div className={classes.btnCard}>
                    <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

               { location.pathname ==='/editlog' &&  (
                <div className={classes.btnCard}>
                    <IconButton component={ Link } to="/logbook" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

              { location.pathname ==='/claimvoucherresponse' &&  (
                <div className={classes.btnCard}>
                    <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}


               { location.pathname ==='/passwordresetresponse' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/passwordreset" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}
              
              
              { location.pathname ==='/voucherownerdetails' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/voucherclaim" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

               { location.pathname ==='/refillfuel' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

              { location.pathname ==='/recharge' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}


              { location.pathname ==='/refillfuelresponse' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/individualrefill" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

              { location.pathname ==='/refillfuelapproval' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}



               { location.pathname ==='/userprofile' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}


             { location.pathname ==='/maintenance' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}


               { location.pathname ==='/individualrefill' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/refillfuelapproval" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}     

              
              { location.pathname ==='/organisationrefill' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/refillfuelapproval" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)} 
                

              { location.pathname ==='/refillhistory' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)} 


          {location.pathname ==='/refillfuelfsresponse' &&  (
              <div className={classes.btnCard}>

                <IconButton component={ Link } to="/refillfuel" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}


          {location.pathname ==='/completefuelrefilltransaction' &&  (
              <div className={classes.btnCard}>

                <IconButton component={ Link } to="/refillfuelapproval" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}    

              {location.pathname ==='/refillfuelorganisationresponse' &&  (
              <div className={classes.btnCard}>

                <IconButton component={ Link } to="/individualrefill" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

              
              { location.pathname ==='/vehiclelist' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <ArrowBackIcon/>
                        </Badge>
                    </IconButton>
              </div>)} 

                  

              
              
            <Drawer setOpen={setOpen} open={open} handleDrawer={handleDrawer}  />
              
                <Typography variant="h6" className={classes.title} color="inherit">
                                   
                     <span style={{color:'white'}}><b>Lets Move </b></span> 
                  
                </Typography>
               

                <div className={classes.grow}/>

   {screenWidth >= '444' ? 
      
                
     <> 

       {rightsID == '5' ? 

          <>


           { location.pathname ==='/dashboard' && (
                <div className={classes.notificationIcon} style={{paddingRight:'10px'}}>
                      <IconButton color="inherit" size="small" align="center" aria-label="menu" onClick={openInTransitVehicles}>
                                 <DepartureBoardIcon style={{color:'white'}}/>                              
                      </IconButton>
                      <p alignItems="center">In Transit</p>
                </div>
            )} 

            { location.pathname ==='/dashboard' && (
                <div className={classes.notificationIcon} style={{paddingLeft:'10px'}}>
                      <IconButton color="inherit" size="small" align="center" aria-label="menu" onClick={openStandbyVehicles}>
                                <GarageIcon style={{color:'white'}} />                                 
                      </IconButton>
                        <p alignItems="center">To Start-off</p>
                </div>
            )} 
              
            

           { location.pathname ==='/dashboard' && (
                <div className={classes.notificationIcon} style={{paddingLeft:'10px',color:'white'}}>
                      <IconButton color="inherit" size="small" align="center" aria-label="menu" onClick={openAvailableVehicle}>
                                <CarRepairIcon />                                 
                      </IconButton>
                        <p alignItems="center">Parked</p>
                </div>
            )} 
           </> 

        :
        
        <>
        </>

        }   
         </>
        :
         <>


            { location.pathname ==='/dashboard' && (
                <div className={classes.notificationIcon} style={{paddingRight:'10px'}}>
                      <IconButton color="inherit" size="small" align="center" aria-label="menu" onClick={openInTransitVehicles}>
                                 <DepartureBoardIcon style={{color:'white'}}/>                              
                      </IconButton>
                </div>
            )} 


            { location.pathname ==='/dashboard' && (
                <div className={classes.notificationIcon} style={{paddingLeft:'10px'}}>
                      <IconButton color="inherit" size="small" align="center" aria-label="menu" onClick={openAvailableVehicle}>
                                <GarageIcon style={{color:'white'}} />                                 
                      </IconButton>
                </div>
            )} 
              
            

           { location.pathname ==='/dashboard' && (
                <div className={classes.notificationIcon} style={{paddingLeft:'10px',color:'white'}}>
                      <IconButton color="inherit" size="small" align="center" aria-label="menu" onClick={openAvailableVehicle}>
                                <CarRepairIcon />                                 
                      </IconButton>
                        
                </div>
            )} 



          </>

        }

      
 





               { location.pathname ==='/vehicleenrollment' &&  (
                <div className={classes.btnCard}>

                    <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>

                </div>

              )}


           


               

              { location.pathname ==='/requisition' &&  (
                <div className={classes.btnCard}>

                    <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

               { location.pathname ==='/logbook' &&  (
                <div className={classes.btnCard}>

                 <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

               { location.pathname ==='/editlog' &&  (
                <div className={classes.btnCard}>

                   <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

              { location.pathname ==='/claimvoucherresponse' &&  (
                <div className={classes.btnCard}>

                    <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

               { location.pathname ==='/passwordresetresponse' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)}
              
              
              { location.pathname ==='/voucherownerdetails' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

               { location.pathname ==='/refillfuel' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

              { location.pathname ==='/recharge' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)}


              { location.pathname ==='/refillfuelresponse' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)}

              { location.pathname ==='/refillfuelapproval' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)}



               { location.pathname ==='/userprofile' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)}


             { location.pathname ==='/maintenance' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)}


               { location.pathname ==='/individualrefill' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)}     

              
              { location.pathname ==='/organisationrefill' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)} 
                

              { location.pathname ==='/refillhistory' &&  (
                <div className={classes.btnCard}>

                <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)} 


              { location.pathname ==='/refillfuelfsresponse' &&  (
                <div className={classes.btnCard}>

                 <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)} 


              { location.pathname ==='/vehiclelist' &&  (
                <div className={classes.btnCard}>

                 <IconButton component={ Link } to="/dashboard" aria-label="show cart items" color="inherit">
                        <Badge badgeContent={cart.length} color="secondary">
                            <HomeIcon/>
                        </Badge>
                    </IconButton>
              </div>)} 




            </Toolbar>
          </AppBar>            
        </>
    )
}

export default NavBar