import useStyles from '../Requisition/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export const Loader = () => {
	
 const classes = useStyles(); 

	return (

           <main className={classes.spinner}>	
                        <div className={classes.toolbar} />
                        <CircularProgress  />
           </main>
	);
}

export default Loader;