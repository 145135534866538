import React, {useState, useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Button, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import {CartContext} from '../../Context/CartContext';
import useStyles from '../Requisition/styles';
import { useHistory } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import axios from 'axios';
import Select from 'react-select';
import { AlertSuccess } from '../Alert/AlertSuccess';



const Registration = () => {

 const classes = useStyles();
 const history = useHistory();
 const { baseUrlContext }= useContext(CartContext);
 const baseUrl = baseUrlContext; 
 const [options, setOptions] = useState([]);
 const [selectedOption, setSelectedOption] = useState(null);
 const [ open, setOpen] = useState(false); 
 const [ email, setEmail]= useState('');
 const [ password, setPassword]= useState('');
 const [ regError, setRegError] = useState('');
 const [ username, setUsername] = useState('');
 const [ cellNumber, setCellNumber]= useState('');
 const [ nationalID, setNationalID] = useState('');
 const [ confirmPassword, setConfirmPassword]= useState('');
 const [ packages, setPackages] = useState([]);
 const [ packagesID, setSelectedPackagesID] = useState('');
 let selectedPackage = '';
 let availablePackages = '';
 let selectedPackagesID = '';
 let LoginDetailsBag = [{}];



  const aquaticCreatures = [
  { label: 'Shark', value: 'Shark' },
  { label: 'Dolphin', value: 'Dolphin' },
  { label: 'Whale', value: 'Whale' },
  { label: 'Octopus', value: 'Octopus' },
  { label: 'Crab', value: 'Crab' },
  { label: 'Lobster', value: 'Lobster' },
  { label: 'Shark1', value: 'Shark1' },
  { label: 'Dolphin1', value: 'Dolphin1' },
  { label: 'Whale1', value: 'Whale1' },
  { label: 'Octopus1', value: 'Octopus1' },
  { label: 'Crab1', value: 'Crab1' },
  { label: 'Lobster1', value: 'Lobster1' },
];





    useEffect(()=> {

       window.scrollTo(0, 0); 
       setOpen(!open);


    
       const res = axios.post(baseUrl+'getPackagePricing.php').then(function (response) {

       availablePackages = response.data; 
       setPackages(response.data);   
       setOptions(response.data);

       });

      
    setOpen(false);  

         
   }, [])




 const goToLoginPage = () => history.push({
     
      pathname: '/login',
      loginState: 'Succesful registered'

});




const HitRegistrationApi = async()=>{

  //  handleValidation();



   let data = {fullName:username, cellNumber, nationalID, packageID:packagesID, email, password};
      

      data = JSON.stringify(data);
         
  const res = await axios.post(baseUrl +'receiveRegistration.php', data )
  .then(function (response) {

  
      if(response.data.code === '200'){
           
             let username = response.data.username
             alert('Please login using '+username+' as your username.')
             goToLoginPage();
             setOpen(false);
      
      }else{

         setRegError(response.data.description);
         setOpen(false);
      }
         
  });
         
}
   
const handlePackageChange  = (event: React.ChangeEvent<HTMLInputElement>) =>{
           
       setSelectedPackagesID(event.target.value);
       selectedPackage = event.target.value;

    }


   

const handleSubmit = () => {

    setOpen(!open);
    setRegError('');
  
  if(username === '' || email === '' || cellNumber === '' || nationalID === '' || password === '' || confirmPassword === ''){

       setRegError('Make sure your fill in all fields.');
       setOpen(false);

    }else{

       HitRegistrationApi();
       setRegError('');

    }

}




  function handleNationalIDValidation() {
    
    if (nationalID ) {
        
 
      if(isNaN(nationalID)){

        setRegError("Please check national ID format");

     }
     else{

          if (nationalID.length < '13' ) {
                           
                     setRegError("Please check national ID number of charecters and try again.");
                
              }else{

                setRegError("");

              }

     }


    }
    else{

        setRegError("national ID format not correct");
    }
 } 
 



  function handleFullnameValidation() {
       
    if (username) {
      
       if (username.length < '5' ) {
       
         setRegError("Full name must have more than 5 charecters");
   
      }else{

        setRegError("");

      }
    }
    else{

        setRegError("Please correct the Full Name");
    }
 }


 function handleCellnumberValidation() {
    
    if (cellNumber) {
      
       if (cellNumber.length < '8' ) {
        
             if(cellNumber.lenth > '7'){

               setRegError("Cell Number must have more than 8 charecters");
                 
             } 

         setRegError("Cell Number must have more than 8 charecters");
   
        }else{

          setRegError("");

        }
      }
      else{

          setRegError("Please correct the Cell Number");
     
      }
   }
 


 function handleEmailValidation() {
    
    let errors = '';

    if (email) {
      
       if (!email.match(/^[a-zA-Z]+$/)) {
       
       //  setRegError("Email Address correct");
   
      }else{

         setRegError("Check mail format");  

      }
    }
    else{

        setRegError("Email Address format not correct");
    }
 }
 

  function handlePasswordValidation() {

      if(password){

        if(password.length < '5'){

            setRegError("Please make sure your password has more than 5 charecters.");
        } 
        else{


          setRegError('');

        } 

       setRegError('');        

      }
      else{

          setRegError("Please check the confirmation password and try again.");

      }

  }


   function handlePasswordConfirmationValidation() {

      if(confirmPassword){

        if(confirmPassword.length < '5'){

            setRegError("Please make sure your password has more than 5 charecters.");
            
         if(confirmPassword !== password){
              
            setRegError("Password and confirmation password should match.");

         }   else{

            setRegError('');
         }
        }else{

            setRegError('');

        }  

      }
      else{

          setRegError("Please check the password you entered and try again.");

      }
  }


    return (
    <main className = {classes.content}>
            <div className={classes.toolbar}/>

               <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
  
                          <Grid item xs={false} sm={2} md={3} lg={4}/>
              
                            <Grid item xs={12} sm={8} md={6} lg={4}>
                                <Card >
                                  
                                 <CardContent>
                                    <div style={{color:"red", align:"center"}}>{regError}</div>
                                  <CardHeader title="Registration"  align="center" />
                                    
                                     <Typography variant="h4" gutterBottom>
                                      <TextField label="National ID" variant="outlined" fullWidth size="small"  placeholder="National ID"
                                       id="nationalID"
                                       name="nationalID"
                                       value={nationalID}
                                       onBlur={handleNationalIDValidation}
                                       onChange={(e)=>setNationalID(e.target.value)}
                                        
                                      />
                                      </Typography> 

                                     <Typography variant="h4" gutterBottom>
                                      <TextField  label="Fullname" variant="outlined" fullWidth size="small" placeholder="John Doe"
                                       id="username"
                                       name="username"
                                       value={username}
                                       onBlur={handleFullnameValidation}
                                       onChange={(e)=>setUsername(e.target.value)}
                                        />
                                      
                                      </Typography> 


                                     <Typography variant="h4" gutterBottom>
                                       <TextField label="Cell Number" variant="outlined" fullWidth size="small"  placeholder="76000000"
                                       id="cellNumber"
                                       name="cellNumber"
                                       value={cellNumber}
                                       onBlur={handleCellnumberValidation}
                                       onChange={(e)=>setCellNumber(e.target.value)}
                                       />
                                     
                                     </Typography>

                                      
                                    
                                     <Typography variant="h4" gutterBottom>
                                      <TextField label="Email" variant="outlined" fullWidth size="small"  placeholder=" example@gmail.com"
                                       id="email"
                                       name="email"
                                       value={email}
                                       onBlur={handleEmailValidation}
                                       onChange={(e)=>setEmail(e.target.value)}
                                        
                                      />
                                      </Typography>


                             <Typography variant="body1" gutterBottom>                
                              <TextField label="Select Package" variant="outlined" fullWidth size="small" select
                                  
                                  onChange={handlePackageChange}
                                  value={packagesID}
                                  > 

                                {packages.map((val) => (

                                   <MenuItem key={val.id} value={val.id} >
                                       {val.packageName}
                                   </MenuItem>

                                ))}
                               
                               </TextField>
                             </Typography>

                             

                        {/*<Typography>
                            <Select
                                
                              defaultValue={packagesID} 
                         
                              onChange={setSelectedOption}
                                                      
                              options={packages.map((val) => (
                                    <>
                                     {val.packageName}

                                    </>  
                                    ))}  
                                    
                                  />
                                  

                                     packages.map((val) => (

                                      <MenuItem key={val.id} value={val.packageName}>

                                        {val}
                                       </Select>   
                                      </MenuItem> 

                                    )) 

                                  
                             </Typography>*/} 

                             
                                    <Typography variant="h4" gutterBottom>
                                      <TextField label="Password" variant="outlined"  fullWidth size="small"  
                                       type="password"
                                       id="password"
                                       name="password"
                                       value={password}
                                       onBlur={handlePasswordValidation}
                                       onChange={(e)=>setPassword(e.target.value)}
                                        />
                                    </Typography>


                                    <Typography variant="h4" gutterBottom>
                                      <TextField label="Confirm Password" variant="outlined" fullWidth size="small"  
                                        type="password"
                                       id="confirmPassword"
                                       name="confirmPassword"
                                       value={confirmPassword}
                                       onBlur={handlePasswordConfirmationValidation}
                                       onChange={(e)=>setConfirmPassword(e.target.value)}
                                        />
                                      </Typography>
                  <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                  </Backdrop>                    


                  <Button className={classes.cardButton}  size="large" type="button" variant="contained" fullWidth onClick={()=>handleSubmit()}>
                                         <span className={classes.btnTxt}>Submit</span>
                  </Button>


                                
                </CardContent>
            </Card>

            </Grid>
          
              <Grid item xs={false} sm={2} md={3} lg={4} />
              </Grid>
          </Grid>
  


  </main>
            
      )
}

export default Registration;