import React, {useState, useEffect, useContext} from 'react';
import {Drawer as MUIDrawer, ListItem, List, ListItemIcon, ListItemText} from "@material-ui/core";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LockIcon from '@material-ui/icons/Lock';
import RegistrationIcon  from '@material-ui/icons/Person';
import ArtistIcon  from '@material-ui/icons/Person';
import FolderOpenIcon  from '@material-ui/icons/FolderOpen';
import MusicNoteIcon  from '@material-ui/icons/MusicNote';
import CreditCardIcon  from '@material-ui/icons/CreditCard';
import HomeIcon from '@material-ui/icons/Home';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HistoryIcon from '@material-ui/icons/History';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { Link , useLocation } from 'react-router-dom';
import axios from 'axios';
import {CartContext} from '../../Context/CartContext';




const Drawer = ({open, setOpen, handleDrawer}) =>{

    const { selectedMusicCategoryBag, baseUrlContext, selectedLocationBag, userLastInteractionBag, loginDetailsBag, usersUnitsBag } = useContext(CartContext); 
    const location = useLocation();
    const [selectedMusicCategoryID, setSelectedMusicCategoryID] = selectedMusicCategoryBag;
    const [selectedLocationID, setSelectedLocationID] = selectedLocationBag;
    const [userLastInteraction, setUserLastInteraction] = userLastInteractionBag;
    const [usersUnits, setUsersUnits] = usersUnitsBag;
    const baseUrl = baseUrlContext;
    const [loginDetails, setLoginDetails] = loginDetailsBag;
    const [deviceDimentionW, setDeviceDimentionW] = useState('');
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());   
    let screenWidth = windowDimensions.width;
    let opionalMenu = '';
    let availableUnits = ''

   if(usersUnits){ 
    	
     availableUnits = usersUnits[0].balance;

   } 
     function getWindowDimensions() {
      
       const { innerWidth: width, innerHeight: height } = window;
                return {
                     width,
                     height
                 };
              
              }






    function handleResize() {

      setWindowDimensions(getWindowDimensions());
      
     }


   

useEffect(() => {  





 setDeviceDimentionW(windowDimensions.width);

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);



 }, []);


       //  PRODUCTION 
         let userPreviledges = loginDetails.rights_id;

	   //  TEST
	   //    let userPreviledges = '5';
	   	       


  function LogOut(){
  
   setLoginDetails({});

  }

		
     let [musicCategories, setMusicCategories] = useState([]);
     let [locations, setLocations] = useState([]);


		   const selectedMusicCategory = (id)=>{
                          
               setSelectedMusicCategoryID(id);
               setUserLastInteraction('musicCategory');
  
           }


           const selectedLocation = (id)=>{
                          
               setSelectedLocationID(id);
               setUserLastInteraction('location');
           }


  let itemsList = [   
				
				{ text: 'Register', icon: <RegistrationIcon />, onClick: '/registration'},
				{ text: 'Login', icon: <FolderOpenIcon />, onClick: '/login'}	
				
	        ]; 

let logOutItemList = [   
			
				{ text: 'Log Out', icon: <LockIcon />, onClick: '/login', customFunction:LogOut}	
				
	        ]; 

 
 
		  
 if(userPreviledges == '5'){

	  
 let balance = loginDetails.fullName;
		
 itemsList = [
    
     { text: ''+balance, icon: < AccountCircleIcon/>, onClick: '/userprofile', customFunction: ''},
     { text: availableUnits, icon: < AttachMoneyIcon/>, onClick: '/recharge', customFunction: ''},
     { text: 'Dashboard', icon: <HomeIcon />, onClick: '/dashboard', customFunction:()=> selectedMusicCategory(0)},       
     { text: 'Recharge', icon: <CreditCardIcon/>, onClick: '/recharge', customFunction: ''}, 	
	 { text: 'Log Book', icon: <MenuBookIcon />, onClick: '/logbook', customFunction: ''},	
	 { text: 'Request vehicle', icon: <AddCircleOutlineIcon />, onClick: '/requisition', customFunction: ''},
	 { text: 'My Vehicles', icon: <DirectionsCarIcon/>, onClick: '/vehicleenrollment', customFunction: ''},	
  	 { text: 'History', icon: <HistoryIcon />, onClick: '/refillhistory', customFunction: ''},
  	 { text: 'Log Out', icon: <LockIcon />, onClick: '/login', customFunction:LogOut}
	  
	]; 
  
}

 if(userPreviledges == '4'){

 let balance = loginDetails.fullName;
		
 itemsList =[
    
      { text:''+balance, icon: < AccountCircleIcon/>, onClick: '/userprofile', customFunction: ''},
      { text:'Dashboard', icon: <HomeIcon />, onClick: '/dashboard', customFunction:()=> selectedMusicCategory(0)},
	  { text:'Refill Fuel', icon: <DirectionsCarIcon/>, onClick: '/refillfuel', customFunction: ''},	
  	  { text:'History', icon: <HistoryIcon />, onClick: '/refillhistory', customFunction: ''},
  	  { text: 'Log Out', icon: <LockIcon />, onClick: '/login', customFunction:LogOut}
	  
	]; 
}

	


	return (

		<MUIDrawer anchor="left" open= {open} onClose={() => setOpen(false)}>
		  
				<List>

		          {itemsList.map((item, index) => {

		           const { text, icon, onClick, customFunction } = item; 
		           return ( 
				            <ListItem button key={text} component={ Link } to={onClick}  onClick={customFunction} >
				             {icon &&  <ListItemIcon>{icon}</ListItemIcon> }
				              <ListItemText primary={text} />
				            </ListItem>
                      );

		          })}
	        </List>

	    {/*    <List>

     <ListItem button key='hotSongs' component={ Link } onClick={ ()=> selectedMusicCategory(0)}  to='/songs'>
	        { loginDetails.id > 0 ?
	              <><MusicNoteIcon/>  <ListItemText  primary="Hot Songs" /> </> 
	              : <h6></h6>

		     }
	 </ListItem>

		    musicCategories.map((item, index) => {

		        const { id, image, categoryName } = item; 
		           return ( 
			            <ListItem button key={id} component={ Link } onClick={ ()=> selectedMusicCategory(item.id)}  to='/songs'>
				            { item.id > 0 ?  <MusicNoteIcon/>  : <h6></h6>

				            }
				            

				              <ListItemText  primary={categoryName} />

				            </ListItem>

                 );

          })
		          
       </List>*/}
        
  </MUIDrawer>

	);
}

export default Drawer;