import React, {useState, useEffect, useContext} from 'react';
import { Grid, IconButton, Menu, TextField, Card, CardContent, CardHeader, Typography, Button, MenuItem } from '@material-ui/core';
import GarageIcon from '@mui/icons-material/Garage';
import DepartureBoardIcon from '@mui/icons-material/DepartureBoard';
import useStyles from './styles';
import axios from 'axios';
import { CartContext } from '../../Context/CartContext';
import { useHistory } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import { Requisited } from './Requisited/Requisited';
import { AlertSuccess } from '../Alert/AlertSuccess';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';



  function Products(){ 
    


    const classes = useStyles(); 
    const history = useHistory();
    const [ products, setProducts] = useState([]);
    const [ loginError, setLoginError] = useState('');
    const { loginDetailsBag, baseUrlContext }= useContext(CartContext); 
    const [ loginDetails, setLoginDetails] = loginDetailsBag;
    const [ open, setOpen] = React.useState(false);  
    const [ purpose, setPurpose] = useState('');
    const [ destination, setDestination] = useState('');
    const [ startTime, setStartTime] = useState('');
    const [ endTime, setEndTime] = useState('');
    const [ availableVehicle, setAvailableVehicle] = useState([]);
    const [ vehicleValue, setVehicleValue] = useState('');
    const [ requisitedVehicles, setRequisitedVehicles] = useState([]);
    const [ employers, setEmployers] = useState([]);
    const [ requisitingEmployers, setRequisitingEmployers] = useState([]);
    const [ selectedEmployer, setSelectedEmployer] = useState(''); 
    const [ employeeNumber, setEmployeeNumber] = useState('');  
    const [ departments, setDepartments ] = useState([]);
    const [ selectedDepartment, setSelectedDepartment] = useState(''); 
    const [ selectedVehicleRequisit, setSelectedVehicleRequisit] = useState('');
    const [ organisationValue, setOrganisationValue] = React.useState([]);
    const [ responseMessage, setResponseMessage] = useState('');
    const [ success, setSuccess] = useState(false);  
    const baseUrl = baseUrlContext;     
    let selectedEmployersIdentity = '';
    let selectedVehicle = ''; 
    let availableVehiclesDetail = '';    
    let myEmployerDetails = {};               
                        




   useEffect(()=> {

       window.scrollTo(0, 0); 
       setOpen(!open);
       getEmployers();
    
   }, [])


 

    const getEmployers = async()=>{

        let nationalID = loginDetailsBag[0].nationalID;
        let data = { nationalID };
     
       const res = axios.post(baseUrl+'getMyEmployers.php',data ).then(function (response) {
     
             setEmployers(response.data);
             setRequisitingEmployers(response.data);
             myEmployerDetails = response.data;
             setOpen(false); 

        });

 }  




const handleEmployerChange  = (event: React.ChangeEvent<HTMLInputElement>) =>{
  
  setSelectedEmployer(event.target.value);
  selectedEmployersIdentity = event.target.value;

    setOpen(!open);

  let data = { selectedCompanyID:selectedEmployersIdentity };


   for (let i = 0; i < employers.length; i++) { 

        let employersID = employers[i].id;

        if(employersID == selectedEmployersIdentity){

          let employee = employers[i].employeeNumber
          setEmployeeNumber(employee)

        }

   }

   
   const res = axios.post(baseUrl+'getVehicles.php',data ).then(function (response) {
     
     
       availableVehiclesDetail = response.data;
       setAvailableVehicle(response.data);
 
        setOpen(false);

        });

     data = { companyID:selectedEmployersIdentity };    

    const resp = axios.post(baseUrl+'getCompanyDepartments.php',data ).then(function (response) {
     
       setDepartments(response.data);
 
    });     

}

 const goToDashboard = () => history.push({
  
  pathname:'dashboard'

})


  
  const handleRequisitedVehiclesChange  = (event: React.ChangeEvent<HTMLInputElement>) =>{
    
    setOpen(!open);

    setSelectedVehicleRequisit(event.target.value);
    selectedEmployersIdentity = event.target.value;
    

  let data = { companyID:selectedEmployersIdentity };

   
   const res = axios.post(baseUrl+'getVehicleRequisitions.php',data ).then(function (response) {
     
         setRequisitedVehicles(response.data);
         setOpen(false);

      });      

}


const handleDepartmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      
      setSelectedDepartment(event.target.value);

}




       //GET LOGIN DETAILS FROM THE PREVIOUS PAGE

         const checkObjType = history.location.loginState;

        if (typeof checkObjType  !== 'undefined' && Object.keys(history.location.loginState).length > 0) {
        
       //   setLoginDetails(history.location.loginState);
       //   history.push({pathname: '/songs', state: {} });
         
      }



const handleVehicleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setVehicleValue(event.target.value);
          selectedVehicle = event.target.value;
          
   }; 




const handleSubmit = async()=>{
      
   setOpen(!open);
   let organisationID = loginDetailsBag[0].id;  

   let data = {vehicleOwnerCompanyID:selectedEmployer, appUserCompanyID:organisationID, vehicleID:vehicleValue, employeeNumber, startTime, endTime, destination  };
    
      data = JSON.stringify(data);
         
     const res = await axios.post(baseUrl +'vehicleRequisition.php', data )
     .then(function (response) {

     

      if(response.data.code === '200'){
   
              setSuccess(true);
              setResponseMessage(response.data);
              setOpen(false);

              setTimeout(() => {

                    setSuccess(!true);
                    goToDashboard();
                
              },2000) 
      
      }else{

          setSuccess(true);
          setResponseMessage(response.data);
          setOpen(false);

          setTimeout(() => {

                setSuccess(!true);
            
          },8000)
      }
         
  });

}

const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    event.currentTarget.type = "text"

}


const onFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    event.currentTarget.type = "text"

}


   return (
    
    <main className = {classes.content}>
     <div className={classes.toolbar}/>
 
        <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={2}>
                        
                     <Grid item xs={12} sm={3} md={4} lg={4}>
                       <Card>
                         <CardContent>
                            <Accordion className={classes.accordion}>
                                   <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                                      <span ><b> Requesting vehicle rules </b></span>
                                   </AccordionSummary>
                                   <hr/>
                              <AccordionDetails>
                                <Typography variant="body2" gutterBottom> 
                                    You can not request for a vehicle that has been requested for certain time slots.  <br/>
                                           
                                            
                                </Typography>
                                   
                              </AccordionDetails>
                            </Accordion>
  
                        </CardContent>
                      </Card>
                  </Grid>   

                  <Grid item xs={12} sm={6} md={4} lg={4}>
                    <Card >
                              { success  ?
                          
                                    <>
                                      <AlertSuccess messsage={responseMessage.description} code={responseMessage.code}/> 

                                    </> 
                                      :  
                                        <div >
                                        </div>
                    
                          }
                            <Backdrop className={classes.backdrop} open={open} >
                                 <Loader/>
                            </Backdrop>


                     <CardHeader title="Vehicle Requisition"  align="center" />
                        <CardContent>
                                    <div style={{color:"red", align:"center"}}>{loginError}</div>
                            
                            <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Employer" variant="outlined" fullWidth size="small" select
                                  onChange={handleEmployerChange}
                                  value={selectedEmployer}
                                  > 

                                    {employers.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.companyName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>

                          <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Department" variant="outlined" fullWidth size="small" select
                                  onChange={handleDepartmentChange}
                                  value={selectedDepartment}
                                  > 

                                    {departments.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.departmentName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>
                                  
                            <Typography variant="h4" gutterBottom>                
                               <TextField label="Select Vehicle" variant="outlined"  fullWidth size="small" select
                                 onChange={handleVehicleChange}
                                 value={vehicleValue} >

                                  {availableVehicle.map((option) => (
                                      <MenuItem key={option.id} value={option.id}>
                                        {option.registrationNumber} 
                                     </MenuItem>
                                   ))}
                               </TextField>
                              </Typography>

                              <Typography variant="h4" gutterBottom >
                                   <TextField label="Start Time" variant="outlined" fullWidth size="small"   
                                   type="datetime-local"
                                   id="startTime"
                                   name="startTime"
                                   value={startTime}
                                   onFocus={(e) => (e.currentTarget.type = "datetime-local")}
                                   onBlur={(e) => (e.currentTarget.type = "text")}
                                   onChange={(e)=>setStartTime(e.target.value)}
                                 />                                   
                               </Typography>

                               {/*placeholder="dd-MM-yyyyTHH:mm:ss"   defaultValue="Date"  */}
                               <Typography variant="h4" gutterBottom >
                                   <TextField label="End Time" variant="outlined" fullWidth size="small"  
                                      type="datetime-local"
                                      placeholder='End Time'
                                      id="endTime"
                                      name="endTime"
                                      value={endTime}
                                      onFocus={(e) => (e.currentTarget.type = "datetime-local")}
                                      onBlur={(e) => (e.currentTarget.type = "text")}
                                      onChange={(e)=>setEndTime(e.target.value)}
                                 />
                               </Typography>

                                 <Typography variant="h4" gutterBottom>
                                  <TextField label="Destination" variant="outlined"  fullWidth size="small"  placeholder="Dvokolwako"
                                  id="destination"
                                  name="destination"  
                                  value={destination}
                                  onChange={(e)=>setDestination(e.target.value)} 
                                 />
                               </Typography>


                               <Typography variant="h4" gutterBottom>
                                  <TextField label="Purpose" variant="outlined"  fullWidth size="small"  placeholder="Project B equipment delivery"
                                 id="purpose"
                                 name="purpose" 
                                 value={purpose}  
                                 onChange={(e)=>setPurpose(e.target.value)}              
                                 />
                               </Typography>
                                 
                         
                          <Backdrop className={classes.backdrop} open={open}>
                              <Loader/>
                          </Backdrop>            

                         <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleSubmit()}>
                               <span className={classes.btnTxt}>Submit</span>
                          </Button>
        
                    </CardContent>
                  </Card>
           
              </Grid>
          
                 
                   <Grid item xs={12} sm={3} md={4} lg={4}>


                     <Grid container spacing={2}>

                       <Card style={{width:'100%'}}>
                    <CardHeader title="Requested Vehicles"  align="center" />
                    <CardContent >
                          <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Employer" variant="outlined" fullWidth size="small" select
                                  onChange={handleRequisitedVehiclesChange}
                                  value={selectedVehicleRequisit}
                                  > 

                                    {requisitingEmployers.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.companyName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>
                          

                          {/*<Grid container direction="column">

                                <Grid item container spacing={2}>
                        
                                  <Grid item xs={6} container spacing={0} direction="column" alignItems="center"
                                   justifyContent="center">
                                        <div className={classes.notificationIcon}>
                                           <IconButton color="inherit" size="small" align="center" aria-label="menu" onClick={handleRequisitedVehiclesChange}>
                                              <DepartureBoardIcon alignItems="center" style={{color:'#CC9933'}}/>
                                                         
                                              </IconButton>
                                              <p alignItems="center">In Transit</p>
                                        </div>
                                  </Grid>

                                  <Grid item xs={6} container spacing={0} direction="column" alignItems="center"
                                   justifyContent="center">
                                        <div className={classes.notificationIcon}>
                                           <IconButton color="inherit" size="small" align="center" aria-label="menu" onClick={handleRequisitedVehiclesChange}>
                                                <GarageIcon style={{color:'#CC9933'}} alignItems="center"/>
                                                          
                                              </IconButton>
                                              <p alignItems="center">Parked</p>
                                        </div>
                                  </Grid>

                                </Grid>  

                           </Grid>*/}       

                    {requisitedVehicles.map((vehicleDetails)=> (

                        <Requisited  

                          driverFullName={vehicleDetails.fullName}
                          driverContact={vehicleDetails.cellNumber}
                          vehicleReg={vehicleDetails.registrationNumber}
                          requisiter = {vehicleDetails.companyName}
                          start = {vehicleDetails.startTime}
                          end = {vehicleDetails.endTime}
                          vehicleNum = {vehicleDetails.vehicleNumber}
                          department = {vehicleDetails.department}
                          destination = {vehicleDetails.destination}
                          createdAt = {vehicleDetails.created_at} 
                          key={vehicleDetails.id}

                        />

                    ))}       
                           <br/>

                      </CardContent>    
                      </Card>
  
                     </Grid>


                   </Grid>
                 </Grid>

              </Grid>

     </main>
            

    );
   
}
export default Products;