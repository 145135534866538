import { makeStyles } from '@material-ui/core/styles';

  let screenHeight = window.innerHeight;


export default makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    minHeight: '200vh',
  //  position: 'relative',
    bottom: 0, 
    top: 0, 
    width: '100%', 
   // height: screenHeight, 
   // minHeight: screenHeight




  },
  root: {
        flexGrow: 1,
        height: screenHeight, 
        minHeight: screenHeight
  },
  banner:{

      width:'100%',
      margin: '0px',
    
  },
  dashboardImage:{
      width:'100%', 
      height:'80px'
  },
  cardButton:{

  	      backgroundColor:'#BB7E2C',
          textTransform: 'none',
          margin: '5px',
          textTransaform:'none!important',
          hover :{
  		          background: '#BB7E2C',
		 
    	     	}
  },
  bannerContainer:{

    width:'100%', 
    display:'flex',
    padding:'0',
    paddingLeft:'0',
    paddingRight:'0',
 
  },
  accordion:{
    
      padding:'10px',
      marginTop:'10'
  },
  accordionIcon:{

      color:'blue'

  },
    footer:{
    
    backgroundColor:'#BB7E2C',
    color:'white',
  //    position:'absolute',
    position: 'fixed',
    paddingTop:'10px',
    width: '100%',
    height: '50px',
    justifyContent:'center',
    alignItems:'center',
    bottom: 0,
    boxShadow: 'none',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    
  },
  btnTxt:{

  		color:'white'
  },
  btnLink:{

       color:'black',
       textTransform: 'none !important',
       textDecoration: 'underline',     
       textDecorationColor: 'blue',
  },  
  notificationIcon:{

        boxShadow: "3px 3px 10px rgba(0, 0, 0, 0.2",
        

  },
  backdrop: {
  
           zIndex: theme.zIndex.drawer + 1,
           color: '#fff',
  
  },
  spinner:{
  
          align:"center", 
          justify:"center",
          position: 'absolute',
          left: '50%',
          top: '50%',

  }

}));