import React, {useState, useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Button, Typography, MenuItem } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import EmailIcon from '@material-ui/icons/Email';
import { CartContext } from '../../Context/CartContext';
import useStyles from '../Requisition/styles'; 
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';



const Login = () => {


      const classes = useStyles();
      const history = useHistory();
      const { baseUrlContext, dataReceiver, usersUnitsBag }= useContext(CartContext);
      const [loginDetails, setLoginDetails] = useState({});
      const [usersUnits, setUsersUnits] = usersUnitsBag;
      const [loading, setLoading] = useState(false);
      const baseUrl = baseUrlContext;
      const [loginError, setLoginError] = useState('');
      let LoginDetailsBag = [{}];
      const [username, setUsername] = useState('');
      const [fullName, setFullName]= useState('');
      const user = {username, fullName};
      const [open, setOpen] = React.useState(false);
      const dataDec  = dataReceiver;
      const [selectedUser, setSelectedUser] = useState('');
      const userTypes = [{id:'1','name':'Fuel Attendant',uri:'/fuelStation/'},
                        {id:'2','name':'Driver',uri:''}
                        ];
      const [uriAppend, setUriAppend] = useState('');                  
      



 const goToHomePage = () => history.push({
         
       pathname: '/dashboard',
       loginState: LoginDetailsBag

});



const handleSwitchUserChange  = (event: React.ChangeEvent<HTMLInputElement>) =>{
  
    setSelectedUser(event.target.value);
   let myCurrentUser = event.target.value;

  //  setOpen(!open);
  
   for (let i = 0; i < userTypes.length; i++) { 

        
        let userTypeID = userTypes[i].id;
       

        if(myCurrentUser == userTypeID){

          let uri =  userTypes[i].uri;

          setUriAppend(uri);
   
     }        
   } 



}




const hitLoginApi = async()=>{
    
        
        setOpen(!open);
        const password = fullName;
        const min = 100;
        const max = 999;
        const rand = Math.floor(Math.random() * (max - min + 1)) + min;
        const randPassword = `${rand}${password}`;


        function d2h(d) {
 
            return d.toString(16);
 
        }

        function h2d(h) {
 
            return parseInt(h, 16);
 
        }

        function stringToHex(tmp) {
            let str = '',
                i = 0,
                tmp_len = tmp.length,
                c;

            for (; i < tmp_len; i += 1) {

                c = tmp.charCodeAt(i);
                str += d2h(c) + '';
                
            }
            return str;
        }

        const rand3hexPassword = stringToHex(randPassword);

    
        let data = { username: username, password: rand3hexPassword }
        data = JSON.stringify(data);
         
   const res = await axios.post(baseUrl +uriAppend+'login.php', data )
  .then(function (response) {

     //   response.data = dataDec(response.data);
      
     if(response.data.code === '200'){
      
             setLoginDetails(response.data);     
             LoginDetailsBag = response.data;


              let data = { companyID:response.data.id}

            
             const res = axios.post(baseUrl+'getCompanyFuelUnits.php',data ).then(function (response) {
             
                     setUsersUnits(response.data);
             

        });


             goToHomePage();
             setOpen(false);

         }else{

          setLoginError(response.data.error);
          setOpen(false);

        }
             
     });       
  }
   

const  handleSubmit = () => {
  
    if(username === '' || fullName === ''){

      setLoginError('Please check your login credentials');

    }else{

       hitLoginApi();
        setLoginError('');
 
    }

    
}


const handleRegister = () => history.push({
      
     pathname: '/registration'
    
});


function handleUsernameValidation (){
  
    
    if(username){

       if (!username.match(/^[a-zA-Z]+$/)) {

          setLoginError('');

       }else{

        setLoginError('Username format not correct. please use your email address.');

       }

        
         
    }else{

    setLoginError('Username cannot be empty.');

    }
  
}  



if(loading){

        return  (
          
           <Loader/>
         
           )
    }


return (
    <main className = {classes.content}>
            <div className={classes.toolbar}/>
                  
             <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        <Grid item xs={false} sm={3} md={3} lg={4}/>
            
                        <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Card >
                                
                          <CardContent>
                               <div style={{color:"red", align:"center"}}>{loginError}</div>
                               <CardHeader title="Login"  align="center" />
                                  
                                   <Typography variant="h4" gutterBottom >                
                              <TextField label="Login As" variant="outlined" fullWidth size="small" select
                                  onChange={handleSwitchUserChange}
                                  value={selectedUser}
                                  > 

                                    {userTypes.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.name} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>   


                                  <Typography variant="h4" gutterBottom>
                                    <TextField label="Email" variant="outlined"  fullWidth size="small"  placeholder=" letsmove@gmail.com"
                                    id="username"
                                    name="username"
                                    value={username}
                                    onBlur={handleUsernameValidation}
                                    onChange={(e)=>setUsername(e.target.value)}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                         <EmailIcon />
                                        </InputAdornment>
                                        ),
                                       }}
                                      />
                                  </Typography>


                                  <Typography variant="h4" gutterBottom>
                                      <TextField label="Password" variant="outlined"  fullWidth size="small"  
                                      type="password"
                                      id="fullName"
                                      name="fullName"
                                      value={fullName}
                                      onChange={(e)=>setFullName(e.target.value)}
                                        
                                      />
                                  </Typography>
  
                          <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                          </Backdrop>            

                         <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleSubmit()}>
                               <span className={classes.btnTxt}>Login</span>
                          </Button>

                         <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleRegister()}>
                                <span className={classes.btnTxt}>Register</span>
                         </Button>
     
                    <Typography variant="body1" gutterBottom>                
                                    
                         <Button component={Link} to="/passwordreset" style={{margin: '5px',textTransaform:'none' ,color:'black'}}>
                               Forgot Password?
                         </Button>
            
                    </Typography>
     
                    </CardContent>
                  </Card>
              </Grid>
          
                 <Grid item xs={false} sm={3} md={4} lg={4}/>
                 </Grid>

              </Grid>

     </main>
            
      )
}

export default Login;