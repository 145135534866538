import React, {useState,useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Typography, MenuItem, Button } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import useStyles from '../Requisition/styles';
import {VehiclesInTransit} from './Vehicles/VehiclesInTransit';
import {VehiclesParked} from './Vehicles/VehiclesParked';
import {VehiclesPersonalized} from './Vehicles/VehiclesPersonalized';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import { useHistory, Link } from 'react-router-dom';

import SplitPaneBottom from '../SplitPane/SplitPaneBottom';
import SplitPaneLeft from '../SplitPane/SplitPaneLeft';
import SplitPaneRight from '../SplitPane/SplitPaneRight';
import SplitPaneTop from '../SplitPane/SplitPaneTop';
import SplitPane from '../SplitPane/SplitPane';
 

const VehicleList = () => {

      const classes = useStyles();
      const history = useHistory();
      const [regError, setRegError] = useState(''); 
      const [vehicleRegistration, setVehicleRegistration ] = useState('');
      const [open, setOpen] = React.useState(false);
      const { baseUrlContext, loginDetailsBag, selectedVehicleCategoryBag, selectedLocationBag }= useContext(CartContext);

     const [selectedVehicleCategoryID, setSelectedVehicleCategoryID] = selectedVehicleCategoryBag;
     const [selectedLocationID, setSelectedLocationID] = selectedLocationBag;

     console.log(selectedVehicleCategoryBag);

      const [loginDetails, setLoginDetails] = loginDetailsBag;
      const baseUrl = baseUrlContext;
      const [addedVehicles, setAddedVehicles] = useState([]);
      const [ selectedEmployer, setSelectedEmployer] = useState('');
      const [employers, setEmployers] = useState([]);
   
      let availableVehiclesDetail = {};
      let selectedEmployersIdentity = '';   

      let publicVehicles = [  
                            {id:'1',name:''},
                            {id:'2',name:'Phakama Laduma Tours'},
                            {id:'3',name:'Vusumuzi Transport'}
                          ];

      let personalVehicles = [
                              {id:'1',name:''},
                              {id:'2',name:'VSD 111 DH'},
                              {id:'3',name:'ASD 121 GH'}
                             ];
        

      const quotes = [
                      {
                        id: 1,
                        author: "Nelson Mandela",
                        description:
                          "The greatest glory in living lies not in never falling, but in rising every time we fall.",
                      },
                      {
                        id: 2,
                        author: "Walt Disney",
                        description: "The way to get started is to quit talking and begin doing.",
                      },
                      {
                        id: 3,
                        author: "Oprah Winfrey",
                        description:
                          "If you look at what you have in life, you'll always have more. If you look at what you don't have in life, you'll never have enough.",
                      },
                    ];                       




  useEffect(()=> {

      window.scrollTo(0, 0);
      setOpen(!open);
      getEmployers();   

   }, [])


  
  const getEmployers = async()=>{
      
        let nationalID = loginDetailsBag[0].nationalID;
        let data = { nationalID };
     
       const res = axios.post(baseUrl+'getMyEmployers.php',data ).then(function (response) {
      
        
       setEmployers(response.data);
       setOpen(false); 

        });

 }  


 const loadVehicles = async()=>{

        let organisationID = loginDetailsBag[0].id;
        let data = { organisationID };
     
       const res = axios.post(baseUrl+'getVehicles.php',data ).then(function (response) {
    
       setOpen(false);
       

       availableVehiclesDetail = response.data;
       setAddedVehicles(response.data); 

        });

 }


const handleEmployerChange  = (event: React.ChangeEvent<HTMLInputElement>) =>{
  
  setSelectedEmployer(event.target.value);
  selectedEmployersIdentity = event.target.value;
    setOpen(!open);

    

 let  data = { companyID:selectedEmployersIdentity };

   
   const res = axios.post(baseUrl+'getVehicleRequisitions.php',data ).then(function (response) {
            setAddedVehicles(response.data);
   //      setRequisitedVehicles(response.data);
   //      setOpen(false);

      });      


    }



const  handleSubmit = async()=>{
        
    
   setOpen(!open);
   let companyID = loginDetailsBag[0].id;  


   let data = { registrationNumber:vehicleRegistration, companyID  };
    
      data = JSON.stringify(data);
         
     const res = await axios.post(baseUrl +'receiveVehicle.php', data )
     .then(function (response) {

      // response.data = dataDec(response.data);
      

      if(response.data.code === '200'){
           
          
           //   loadVehicles();
              alert('added');
              setOpen(false);
      
      }else{

         setRegError(response.data.description);
         setOpen(false);
      }
         
  });
}


  const openTestTable = () => history.push({
  
  pathname:'historytest'

})


  

return (
    <main className = {classes.content}>
            <div className={classes.toolbar}/>
      {/*
         <SplitPane className="split-pane-row">
            <SplitPaneLeft>
              <SplitPane className="split-pane-col">
                <SplitPaneTop />
                <div className="separator-row" />
                <SplitPaneBottom />
            </SplitPane>
          </SplitPaneLeft>
          <div className="separator-col" />

          <SplitPaneRight />
        </SplitPane>
      */}  

            <Grid container justify="right" direction="column">

            <Grid item container spacing={4} justify="center" >
                     
                  <Grid item xs={12} sm={3} md={4} lg={4}>
                       <Card>


                     <CardHeader title="Employer Vehicles"  align="center" />
                         <CardContent>
                            <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Employer" variant="outlined" fullWidth size="small" select
                                  onChange={handleEmployerChange}
                                  value={selectedEmployer}
                                  > 

                                    {employers.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.companyName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>

                      {addedVehicles.map((vehicleDetails)=> (                   

                              <VehiclesInTransit  
                                  vehicleRegistration={vehicleDetails.registrationNumber}
                                  vehicleDriver={vehicleDetails.fullName} 
                                  driversContact={vehicleDetails.cellNumber} 
                                  destination={vehicleDetails.destination}
                                  departure={vehicleDetails.startTime}
                                  endTime={vehicleDetails.endTime}
                                  key={vehicleDetails.id}

                              />                      
                       
                  ))}    

                     <br/>
  
              </CardContent>
            </Card>
        </Grid>
            
        <Grid item xs={12} sm={6} md={4} lg={4}>

                  <Card > 
                       <CardHeader title="Public Transport"  align="center" />                                   
                              <CardContent >

                              <Backdrop className={classes.backdrop} open={open} >
                                  <Loader/>
                              </Backdrop>
                        <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Date" variant="outlined" fullWidth size="small" select
                                  onChange={handleEmployerChange}
                                  value={selectedEmployer}
                                  > 

                                    {publicVehicles.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.name} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>

                  {/*addedVehicles.map((vehicleDetails)=> (*/} 
                      

                    <VehiclesParked 
                        

                    />
                       
                      {/*   vehicleDetails={vehicleDetails} 
                        vehicleReg={vehicleDetails.registrationNumber} 
                        vehicleNum={vehicleDetails.vehicleNumber}
                       
                        key={vehicleDetails.id}))*/}    

                     <br/>


                        </CardContent>
                    </Card>
                </Grid>
          
                <Grid item xs={12} sm={3} md={4} lg={4}>


                   <Grid container spacing={2}>
                     
                   <Card style={{width:'100%'}}>
                    <CardHeader title="Personal Vehicles"  align="center" />
                    <CardContent >
                          <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Date" variant="outlined" fullWidth size="small" select
                                  onChange={handleEmployerChange}
                                  value={selectedEmployer}
                                  > 

                                    {personalVehicles.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.name} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>

                  {/*addedVehicles.map((vehicleDetails)=> ( */}
                      

                    <VehiclesPersonalized 
                       

                    />
                       
                      {/* vehicleDetails={vehicleDetails} 
                        vehicleReg={vehicleDetails.registrationNumber} 
                        vehicleNum={vehicleDetails.vehicleNumber}
                       
                        key={vehicleDetails.id     }))*/}    

                     <br/>

                      </CardContent>    
                      </Card>

                   </Grid>  
                </Grid>
            </Grid>
           </Grid>
          </main>
            
      )
}

export default VehicleList;