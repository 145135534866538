import React, {useContext, useState} from 'react';
import { Grid} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import AddIcon from '@material-ui/icons/Add';
import useStyles from '../../Requisition/styles';  
import {CartContext} from '../../../Context/CartContext';





export const Requisited = ({driverFullName, driverContact, vehicleReg, requisiter, start, end, vehicleNum, department,destination,createdAt}) => {


 const classes = useStyles(); 

 const { loginDetailsBag, cartBag }= useContext(CartContext);
 const [loginDetails, setLoginDetails] = loginDetailsBag;
 const[loading, setLoading] = useState(false); 

     if(loading){
 
        return <h2>Loading..</h2>
 
    }

  
    return (
        <>
     
     <Grid item xs={12} sm={12}>
            
                                
                    
               <Accordion className={classes.accordion}>
                          <AccordionSummary expandIcon={<ArrowDropDownOutlinedIcon className={classes.accordionIcon} />} >
                            
                               Registration# : <span> {vehicleReg}</span>
                          </AccordionSummary>
                    
                          <AccordionDetails >
                           <span style={{borderTop: '1px solid rgba(0, 0, 0, 0.12)', width:'100%'}}> 
                            <p >
                               

                                  <b>Organisation</b> {requisiter}

                                  <br/>

                                  <b>Drivers Fullname</b> {driverFullName}

                                  <br/>

                                  <b>Drivers Cellnumber</b> {driverContact}

                                  <br/>

                                  <b> Start:</b> {start}

                                  <br/>

                                  <b> End:</b> {end}

                                  <br/>  

                                  <b> Department:</b> {department}

                                  <br/>

                                   <b> Vehicle Number:</b> {vehicleNum} 

                                  <br/>

                                  <b> Destination:</b> {destination} 

                                  <br/>
                                  
                                  <b>  Requisition Date:</b> {createdAt}

                                  
                            </p>
                           </span> 


                                                          
                          </AccordionDetails>

              
                      </Accordion>  


                 
            </Grid>
   
 </> 

    )
}

export default Requisited;
