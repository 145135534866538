import React,{useState, useContext} from 'react';
import {Card,  CardContent, Typography, Button} from '@material-ui/core';
import useStyles from '../../Requisition/styles';
import { useHistory } from 'react-router-dom';
import{AlertSuccess} from '../../Alert/AlertSuccess';
import{AlertError} from '../../Alert/AlertError';
import {CartContext} from '../../../Context/CartContext';   
import axios from 'axios';


export const Transactions = ({transaction}) => {

 const classes = useStyles(); 
 const history = useHistory();
 const [open, setOpen] = React.useState(false);
     const [responseMessage, setResponseMessage] = useState('');
      const [success, setSuccess] = useState(false); 
      const [error, setError] = useState(false);     
 
 const { baseUrlContext, loginDetailsBag } = useContext(CartContext);
 const baseUrl = baseUrlContext;
 const [ proofOfPayment, setProofOfPayment ] = useState([]);
 let proofOfPaymentDetailsBag = {};




const approveTransaction = async(id)=>{

   setOpen(!open);
  
   let data = { transactionID:transaction.id };
    
      data = JSON.stringify(data);
         
     const res = await axios.post(baseUrl +'manualApproveFuelStationTransaction.php', data )
     .then(function (response) {

 
      if(response.data !== ''){
           
          
          setSuccess(true);
          setResponseMessage(response.data);
          setOpen(false);

          setTimeout(() => {

                setSuccess(!true);
                         
          },5000)
              
      
      }else{

       
          setError(true);
          setResponseMessage(response.data);
          setOpen(false);

          setTimeout(() => {

                setError(!true);
            
          },5000)

               
      }
  });
}




	return (

    <>
     
            <Card style={{paddingLeft:'20px',paddingLeft:'20px'}}>
                          { success  ?
                          
                                    <>
                                      <AlertSuccess messsage={responseMessage.description}/> 

                                    </> 
                                      :  
                                    <>
                                      {/*<AlertError messsage={responseMessage.description}/>*/} 

                                    </> 
                    
                          }

                          <hr></hr>        
                          <Typography variant="body2" gutterBottom >                
                            <b>{transaction.transactionNumber}     </b>
                          </Typography>
                           <hr></hr> 

                          <Typography variant="body2" gutterBottom>                
                             Registration #:<b>{transaction.registrationNumber} </b>
                          </Typography>
                          
                          <Typography variant="body2" gutterBottom>                
                             Fuel Name:<b>{transaction.fuelName} </b>
                          </Typography>

                          <Typography variant="body2" gutterBottom>                
                            Amount: <b>E{transaction.amount} </b>
                          </Typography>

                          <Typography variant="body2" gutterBottom>                
                            Quantity: <b>{transaction.litres} </b>
                          </Typography>

                          <Typography variant="body2" gutterBottom>                
                            Garage / Filling Station:<b> {transaction.stationName} </b>
                          </Typography>

                          <Typography variant="body2" gutterBottom>                
                          <b>   Transaction Status: {transaction.statusName} </b>
                          </Typography>


                               
              <Typography variant="body2" gutterBottom >                   
                                            
                 <Button className={classes.cardButton} style={{marginLeft:"0px"}} size="large" type="button" fullWidth variant="contained" onClick={()=> approveTransaction()}  >
                     <span className={classes.btnTxt}>
                       Approve
                     </span>  

                </Button>
              </Typography>   
            </Card>
   
 </> 

	);
}

export default Transactions;