import React,{useEffect, useState} from 'react';
import { useHistory, Link } from 'react-router-dom';
import useStyles from '../Requisition/styles';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export const AlertSuccess = ({messsage, code}) => {

       const classes = useStyles();
       const history = useHistory();
       const [open, setOpen] = useState(true);
       let status = ''


	     if(code === '200'){
         
          status = 'passed';

            }else{

          status = '';

         }

   


	 useEffect(()=> {

     if(messsage){

			 setOpen(true);
 
  	   }

   }, [])

 const completeAction = () => {

       setOpen(false);
       goToDashboard()

   }


  const goToDashboard = () => history.push({
  
        pathname:'dashboard'

})


   const handleClickOpen = () => {
 
    setOpen(true);
 
  };

  const handleClose = () => {
 
    setOpen(false);
 
  };


return (
 < >

  <div align='center' style={{width:'100%', paddingTop:'60px'}}>            
   	 <Box sx={{ width: '100%' }}>
				
	
       <Collapse in={open}>
        {status !== '' ?   
         <Alert
               severity="success"
                      action={
                              <IconButton>
                                  <Button variant="text" aria-label="close"
                                          color="inherit"
                                          size="small"
                                          onClick={() => {
                                          completeAction();
                                          }}>
                                             Done
                                  </Button>

                                  {/*<CloseIcon fontSize="inherit" />*/}
                              </IconButton>
                            }
                  sx={{ mb: 2 }}
            >
           <strong> {messsage}</strong>
        </Alert>

        :

         <Alert
                      severity="error"
                      action={
                                <IconButton>
                                    <Button variant="text" aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              completeAction();
                                            }}>
                                                Done
                                    </Button>

                                  
                                </IconButton>
                              }
                  sx={{ mb: 2 }}
            >
           <strong> {messsage}</strong>
        </Alert>
        
        } 



      </Collapse>
          
</Box>
	    </div>

  </>

   )
}

export default AlertSuccess;     