import React, {useState, useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Button, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SearchIcon from '@material-ui/icons/Search';
import Gender from '@material-ui/icons/Wc';
import CallIcon from '@material-ui/icons/SettingsPhone';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import Box from '@material-ui/core/Box';
import {CartContext} from '../../Context/CartContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import useStyles from '../Requisition/styles';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

  

const Recharge = () => {

      const classes = useStyles();
      const history = useHistory();
      const [ regError, setRegError] = useState(''); 
      const [ amount, setAmount] = useState('');
      const [ isGatewayPage, setIsGatewayPage] = useState(false);
      const { baseUrlContext, dataReceiver, loginDetailsBag, usersUnitsBag }= useContext(CartContext);
      const [ loginDetails, setLoginDetails] = loginDetailsBag;
       const [usersUnits, setUsersUnits] = usersUnitsBag;
      const [ gatewayUrl, setGatewayUrl] = useState('');
      const baseUrl = baseUrlContext;
      const dataDec = dataReceiver;
      let rightsID = loginDetails.rights_id;

      let gatewayLink = 'https://www.epaynetsz.com/ePayNetCart/gt00001.php?c=35373131&2c=37&3c=3132&tb='+amount+'&tn='+loginDetails.companyNumber;


 
 useEffect(()=> {

      window.scrollTo(0, 0);
      
   }, [])


 
const iFrameStyle = {

  fontSize: '15px',
  textAlign: 'center',
  backgroundColor: 'white',
  width:'100%',
  height:'600px'

};
 
const  handleSubmit = () => {

setGatewayUrl(gatewayLink);
setIsGatewayPage(true);

 let data = { companyID:rightsID}


             const res = axios.post(baseUrl+'getCompanyFuelUnits.php',data ).then(function (response) {
             
                     setUsersUnits(response.data);
             

        });

  
}

const  GoToEpayNet = () => {

  setGatewayUrl('https://www.epaynetsz.com/ePayNetWeb/');
  setIsGatewayPage(true); 
   
}



return (
    <main className = {classes.content}>
            <div className={classes.toolbar}/>

   
  <Grid container justify="right" direction="column">

        <Grid item container spacing={4} justify="center" >
                     
          <Grid item xs={false}  xs={12} sm={12} md ={6} lg={4} >
             <Card>
               <CardContent>
                 
                   <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                   <span ><b> Stage 1. Not registered on ePayNet  </b></span>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                                         
                                Go to ePayNet, register, login, activate online check-out and go to Stage 3. NB: If you are already registered, go to Stage 2.
                          
                    </Typography>
                       
                  </AccordionDetails>
                </Accordion>



                <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                   <span ><b> Stage 2: Registered ePayNet user </b></span>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                                         
                               Go to ePayNet, login, click on the ePayNet side menu, select online check-out, select “Yes” and update and then go to Stage 3 to recharge.
                          
                    </Typography>
                       
                  </AccordionDetails>
                </Accordion>


                <Accordion className={classes.accordion}>
                    <AccordionSummary expandIcon={<AddIcon className={classes.accordionIcon} />} >
                   <span ><b>Stage 3: Registered on ePayNet & activated online check-out </b></span>
                    </AccordionSummary>
                    <hr/>
                  <AccordionDetails>
                    <Typography variant="body2" gutterBottom> 
                                         
                                Login to ThumbsUp. On the side menu click on "Recharge". Enter amount of money to recharge with and click on "Submit". Enter your ePayNet username and password then click on "Submit". You will receive a push from MoMo to approve payment, if you DO NOT received it, dial *007*2# to approve.
                          
                    </Typography>
                       
                  </AccordionDetails>
                </Accordion>  
                                </CardContent>
                          </Card>

                        </Grid>
            
                        <Grid item xs={12} sm={12} md ={12} lg={4} >

                        { !isGatewayPage ? 
                          <Card > 
                            {gatewayUrl}
                                
                               <CardContent >
                                    <div style={{color:"red", align:"center"}}>{regError}</div>
                                  <CardHeader title="Recharge Via ePayNet"  align="center" />
                                  
                                  
                                      <Typography variant="h4" gutterBottom>
                                      <TextField label="Amount" variant="outlined"  fullWidth size="small"  placeholder="1.00"
                                       id="amount"
                                       name="amount"
                                       value={amount}
                                       onChange={(e)=>setAmount(e.target.value)}
                                        
                                      />
                                      </Typography>


                                  <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleSubmit()}>
                                      <span className={classes.btnTxt}>
                                          Submit
                                      </span>    
                                  </Button>


                                  <br/>
                                   <Typography variant="h4" gutterBottom>
                                     
                                   </Typography>
                                  <Typography variant="body2" gutterBottom>
                                     For ePayNet processes like registration, enabling eCommerce and etc on your ePayNet Account, please click the following button.

                                   </Typography>

                                  <Button md className={classes.cardButton}   size="large" type="button" variant="contained" fullWidth onClick={()=>GoToEpayNet()}>
                                        <span className={classes.btnTxt}>
                                          Go To ePayNet
                                        </span>
                                  </Button>

                                  
                           </CardContent>
                         </Card>

                         :
                          
                            <div  style={iFrameStyle}>
                              <iframe
                                width="100%"
                                height="100%"
                                src= {gatewayUrl}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Embedded youtube"
                              />
                            </div>
                             

                       }
                           

                        </Grid>
          
                    <Grid item xs={false}  sm={4}/>
                 </Grid>

              </Grid>

  </main>
            

      )
}

export default Recharge;