import React, {useState, useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Typography, Button, } from '@material-ui/core';
import Requisited from '../Requisition/Requisition';
import useStyles from '../Requisition/styles';
import axios from 'axios';
import {CartContext} from '../../Context/CartContext';
import { useHistory} from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import{AlertSuccess} from '../Alert/AlertSuccess';


 
  function LogBook(){
    
    const classes = useStyles();
    const history = useHistory(); 
    const[loading, setLoading] = useState(false); 
    const [loginError, setLoginError] = useState('');
    const {loginDetailsBag, selectedLocationBag, baseUrlContext, userLastInteractionBag, selectedMusicCategoryBag }= useContext(CartContext); 
    const [loginDetails, setLoginDetails] = loginDetailsBag;
    const baseUrl = baseUrlContext;
    const [open, setOpen] = useState(false);  
    let selectedVehicle = '';                    
    const [vehicleValue, setVehicleValue] = useState([]);                    
    let selectedOrganisation = '';                    
    const [organisationValue, setOrganisationValue] = useState([]);
    const [endTime, setEndTime] = useState('');   
    const [endMileage, setEndMileage] = useState('');  
    const [endFuel, setEndFuel] = useState('');    
    const [responseMessage, setResponseMessage] = useState('');
    const [success, setSuccess] = useState(false);     
             

     const logBookDetailsBag = history.location.editLogBookState

     


     let appUserCompanyID = loginDetailsBag[0].id;
     let registration = logBookDetailsBag.vehicleReg;
     let vehicleNumber = logBookDetailsBag.vehicleNum;
     let startTime = logBookDetailsBag.startTime;
     let vehicleID = logBookDetailsBag.vehicleID;
     let startMileage = logBookDetailsBag.startMileage;
     let startFuel = logBookDetailsBag.startFuel;
     let tripID = logBookDetailsBag.tripId;
     let vehicleOwnersID = logBookDetailsBag.vehicleOwner;

 

  useEffect(()=> {

      window.scrollTo(0, 0);
      

   }, [])



   useEffect(()=> {

      setLoading(true);
      window.scrollTo(0, 0);       
   
   }, [])


 const goToDashboard = () => history.push({
  
  pathname:'dashboard'

})

 const handleSubmit = async()=>{
        
   setOpen(!open);

   let organisationID = loginDetailsBag[0].id;  


   let data = { vehicleOwnerCompanyID:vehicleOwnersID, appUserCompanyID:organisationID, vehicleID, endTime, endMileage, tripID };
    
   data = JSON.stringify(data);

         
     const res = await axios.post(baseUrl +'endTrip.php', data ).then(function (response) {


      if(response.data.code === '200'){
           
              setSuccess(true);
              setResponseMessage(response.data);
              setOpen(false);

              setTimeout(() => {

                    setSuccess(!true);
                    goToDashboard(); 
                
              },2000)
      
      }else{

              setSuccess(true);
              setResponseMessage(response.data);
              setOpen(false);

              setTimeout(() => {

                    setSuccess(!true);
                
              },8000)
      }
         
  });

}





 const doneUpdating = () => history.push({
  
  pathname:'logbook'

})


    return (
      <main className = {classes.content}>
            <div className={classes.toolbar}/>

         <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                            <Grid item xs={false} sm={2} md={3} lg={4}>      
                     </Grid>   
            
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Card > 


                         { success  ?
                          
                                    <>
                                      <AlertSuccess messsage={responseMessage.description} code={responseMessage.code}/> 

                                    </> 
                                      :  
                                        <div >
                                        </div>
                    
                          }        
                      <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                      </Backdrop>

                  <CardHeader title="Editing Return Values"  align="center" />
                      
                      <Typography variant="body2" gutterBottom>
                      <div style={{color:"red", align:"center"}} align="center">{loginError}</div>
                      </Typography> 

                    <CardContent>
                  
                              <Typography variant="h4" gutterBottom>
                                     <TextField label="Vehicle Registration Number" variant="outlined"  fullWidth size="small" value={registration}  
                                        type="text"
                                       id="registration"
                                       name="registration"
                                      />
                              </Typography>

                              


                              <Typography variant="h4" gutterBottom>
                                     <TextField label="Vehicle  Number" variant="outlined"  fullWidth size="small" value={vehicleNumber}  
                                        type="text"
                                       id="vehicleNumber"
                                       name="vehicleNumber"
                                      />
                              </Typography>

                              

                               <Typography variant="h4" gutterBottom>
                                   <TextField label="Time Start" variant="outlined"  fullWidth size="small"  value={startTime}
                                    id="startTime"
                                    name="startTime"                 
                                   />
                                 </Typography>

                               <Typography variant="h4" gutterBottom >
                                   <TextField label="End / Return Time" placeholder="End / Return Time" variant="outlined" fullWidth size="small"   
                                   type="datetime-local"
                                   id="endTime"
                                   name="endTime"
                                   value={endTime}
                                   onFocus={(e) => (e.currentTarget.type = "datetime-local")}
                                   onBlur={(e) => (e.currentTarget.type = "text")}
                                   onChange={(e)=>setEndTime(e.target.value)}
                                      />
                                   
                               </Typography>

                             
                               <Typography variant="h4" gutterBottom>
                                 <TextField label="Start Mileage" variant="outlined"  fullWidth size="small"  value={startMileage}
                                    id="startMileage"
                                    name="startMileage"                 
                                 />
                               </Typography>

                               <Typography variant="h4" gutterBottom >
                                   <TextField label="End / Return Mileage" variant="outlined" fullWidth size="small"   
                                   type="text"
                                   id="endMileage"
                                   name="endMileage"
                                   value={endMileage}
                                   onChange={(e)=>setEndMileage(e.target.value)}
                                      />
                                   
                               </Typography>

                                                                                                                  
                          <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                          </Backdrop>            

                         <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleSubmit()}>
                               <span className={classes.btnTxt}>Submit</span>
                          </Button>         
     
                    </CardContent>
                  </Card>
              </Grid>
                           
              <Grid item xs={false} sm={2} md={3} lg={4}>
                      
            </Grid>
          </Grid>
        </Grid>

     </main>
            
    );
}
export default LogBook;