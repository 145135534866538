import React, {useState, useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Typography, Button, MenuItem} from '@material-ui/core';
import Requisited from '../../Requisition/Requisition';
import useStyles from '../../Requisition/styles';
import axios from 'axios';
import {CartContext} from '../../../Context/CartContext';
import { useHistory} from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../../Loader/Loader';



  function IndividualRefill(){
    

    const classes = useStyles();
    const history = useHistory();
    const[loading, setLoading] = useState(false); 
    const [error, setError] = useState('');
    const {loginDetailsBag, selectedLocationBag, baseUrlContext, userLastInteractionBag, selectedMusicCategoryBag }= useContext(CartContext); 
    const [vehicleValue, setVehicleValue] = React.useState([]);                    
    const [organisationValue, setOrganisationValue] = React.useState([]);
    const [requisitedVehicles, setRequisitedVehicles] = useState([]);
    const [fuelQuantity,setFuelQuantity] = useState('');
    const [fuelType, setFuelType] = useState('');
    const [loginDetails, setLoginDetails] = loginDetailsBag;
    const baseUrl = baseUrlContext;
    const [open, setOpen] = React.useState(false);    
    const [companyID, setCompanyID] = useState('');   
    const [registrationNumber, setRegistrationNumber] = useState('');  
    const [liquidType, setliquidType] = useState('');      
    const [fuelName, setFuelName] = useState('');         
    const [amount, setAmount ] = useState('');
    const [quantity, setQuantity ] = useState('');
    const [employeeID, setEmployeeID ] = useState('');
    const [fuelTypes, setFuelTypes] = useState([]);
    const [availableFuels, setAvailableFuels] = useState([]);
    const [employers, setEmployers] = useState([]);
    const [refillDetails, setRefillDetails] = useState('');
    const [selectedEmployer, setSelectedEmployer] = useState('');
    const [selectedFuel, setSelectedFuel] = useState('');
    const [mySelectedFuelType, setMySelectedFuelType] = useState('');
    const [mileage, setMileage] = useState('');
    const [employeeNumber ,setEmployeeNumber] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [allowedFuelBalance, setAllowedFuelBalance] = useState('');
    const [success, setSuccess] = useState(false);     
    let refillTransactionDetail = [{}];
    let selectedFuelType = '';
    let selectedVehicle = ''; 
    let availableVehiclesDetail = '';  
    let selectedOrganisation = '';    
    let employerID = '';
    const [refillCode, setRefillCode ] = useState(''); 
    const [historyCompanies, seHistoryCompanies] = useState([]);
    const [selectedHistoryCompany, setSelectedHistoryCompany] = ('');                   

     
   useEffect(()=> {

       setOpen(!open);
       let organisationID = loginDetailsBag[0].id;
       let nationalID = loginDetailsBag[0].nationalID;
       let data = { organisationID };

  setOpen(false);
     
    
       const res = axios.post(baseUrl+'/fuelStation/getFuelTypes.php',data ).then(function (response) {

             setFuelTypes(response.data);     
       
       });

       data = {nationalID}

        const response = axios.post(baseUrl+'getMyEmployers.php',data ).then(function (response) {

             setEmployers(response.data); 
             seHistoryCompanies(response.data)    
       
       })
   }, [])


   const handleVehicleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setVehicleValue(event.target.value);
          selectedVehicle = event.target.value;

    }; 


   const handleEmployerChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setSelectedEmployer(event.target.value);
          employerID = event.target.value;
      
             for (let i = 0; i < employers.length; i++) { 

        let myEmployer = employers[i].id;

        if(employerID == myEmployer){


          let companyID = employers[i].id;
          let employeeNumber = employers[i].employeeNumber;
          setEmployeeNumber(employeeNumber);
          setCompanyID(companyID);
          setAllowedFuelBalance(employers[i].fuelUnitsBalance);
            

            }

        }
      
    }; 



const handleFuelTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setMySelectedFuelType(event.target.value);
          selectedFuelType = event.target.value;
          let data = { fuelTypeID:selectedFuelType };
   
   const res = axios.post(baseUrl+'fuelStation/getFuels.php',data ).then(function (response) {
     
        setAvailableFuels(response.data);
        setOpen(false);

        });
          
   }; 


   const handleFuelChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setSelectedFuel(event.target.value);  
  
   }; 


const goToCompleteTransaction = () => history.push({
       
        pathname: '/refillfuelorganisationresponse',
        refillTransactionState: refillTransactionDetail

});



 const goToApprove = async()=>{
  

    let data = {companyID, employeeNumber, refillCode};
         
     const res = await axios.post(baseUrl +'getEmployeeFillingTransactions.php', data )
     .then(function (response) {

    
      if(response.data.code === '200'){
                
                refillTransactionDetail = response.data; 

             
                  setSuccess(true);
                  setResponseMessage(response.data);
                  setOpen(false);
                  goToCompleteTransaction();

                  setTimeout(() => {

                    
                    setSuccess(!true);
                                                       
              },5000)

                     

              
      
      }else if(response.data.code == '201'){


         setError(response.data.error);
         setOpen(false);

      }else{

          
          setError(response.data.error);
          setOpen(false);

      }
         
  });

 }  


const getOrganizationTransactionHistory = () =>{
  

}





 const handleSubmit = async()=>{
        

   //SUBMITTING FOR ORGANISATION REFILL     
   setOpen(!open);

   let fuelStationUserID = loginDetailsBag[0].id; 
   let fuelStationID = loginDetailsBag[0].fuelStations_id;
   let employeeID = loginDetailsBag[0].employeeNumber;

 
   let data = {

                companyRef:companyID, 
                registrationNumber, 
                fuelTypeID:mySelectedFuelType, 
                fuelID:selectedFuel,
                liters:quantity, 
                amount, 
                employeenumber:employeeID, 
                fuelStationUserID, 
                fuelStationID 
              };
    
      data = JSON.stringify(data);
 
     const res = await axios.post(baseUrl +'fuelStation/fsTriggerFuelPayment.php', data )
     .then(function (response) {
         

         console.log('SUBMIT CLICKED')
  
      if(response.data.code == 200){
           
              alert(response.data.description);
              setOpen(false);
              goToResponseStatus();
          
      
      }else{


         setError(response.data.error);
         setOpen(false);

      }
         
  }); 

}





 const goToResponseStatus = () => history.push({
  
  pathname:'refillfuelorganisationresponse'

})


    return (
      <main className = {classes.content}>
            <div className={classes.toolbar}/>

         <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                     
                     <Grid item xs={12} sm={3} md={4} lg={4}>      
                     



              <Card style={{width:'100%'}}>               

                  <CardHeader title="Organization Transaction History"  align="center" />
                      
                      <Typography variant="body2" gutterBottom>
                      <div style={{color:"red", align:"center"}} align="center">{error}</div>
                      </Typography> 

                    <CardContent>
                                  
                          <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Employer" variant="outlined" fullWidth size="small" select
                                  onChange={getOrganizationTransactionHistory}
                                  value={selectedHistoryCompany}
                                  > 

                                    {historyCompanies.map((company) => (
                                    <MenuItem key={company.id} value={company.id} >
                                       {company.companyName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>

                             
                            

     
                    </CardContent>
                  </Card>

                  


                     </Grid>





                      <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                      </Backdrop>





            
            <Grid item xs={12} sm={4} md={6} lg={4}>
              <Card >
                            
                      <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                      </Backdrop>

                  <CardHeader title="Organization Refill"  align="center" />
                      
                      <Typography variant="body2" gutterBottom>
                      <div style={{color:"red", align:"center"}} align="center">{error}</div>
                      </Typography> 

                    <CardContent>
                          
                          {selectedEmployer ?
                             <Typography variant="body2" gutterBottom >
                              <p> You are allowed to refill E{allowedFuelBalance} </p>
                             </Typography>

                          :
                          <>
                          </>

                          }   
                               
                               <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Employer" variant="outlined" fullWidth size="small" select
                                  onChange={handleEmployerChange}
                                  value={selectedEmployer}
                                  > 

                                    {employers.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.companyName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>

                               <Typography variant="h4" gutterBottom >
                                   <TextField label="Employee Refill Code" placeholder="Refill Code (0000)" variant="outlined" fullWidth size="small"   
                                   type="text"
                                   id="refillCode"
                                   name="refillCode"
                                   value={refillCode}
                                   onChange={(e)=>setRefillCode(e.target.value)}
                                      />
                                   
                               </Typography>

                                  
           

                            <Button className={classes.cardButton}
                                         type="button" variant="contained" fullWidth color="inherit" onClick={goToApprove}>
                                         <span className={classes.btnTxt}>Submit</span>
                            </Button>    

     
                    </CardContent>
                  </Card>
              </Grid>
                           
              <Grid item xs={false} sm={4} md={4} lg={4}>
                      
            </Grid>
          </Grid>
        </Grid>

     </main>
            
    );
}
export default IndividualRefill;