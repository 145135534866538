import React, {useContext, useState} from 'react';
import {Card, CardMedia, Button, CardContent, CardActions, Typography, IconButton, Grid} from '@material-ui/core';
import { AddShoppingCart, Headset as PlayIcon, Person as ArtistIcon, PausePresentation as PauseIcon, ThumbUpTwoTone as LikeIcon, FavoriteBorderTwoTone as HeartIcon, ThumbDownTwoTone as DislikeIcon } from '@material-ui/icons';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import AddIcon from '@material-ui/icons/Add';
import useStyles from '../../Requisition/styles'; 
import {CartContext} from '../../../Context/CartContext';
import { useHistory, Link } from 'react-router-dom';

 


export const LoggedVehicle = ({requisitionID, fullname, cellnumber, vehicleOwner, vehicleID, tripId, vehicleNum, vehicleReg, startTime, startMileage, startFuel, destination, date}) => {

 const classes = useStyles(); 
 const { loginDetailsBag, cartBag }= useContext(CartContext);
 const [loginDetails, setLoginDetails] = loginDetailsBag;
 const[loading, setLoading] = useState(false); 
 const history = useHistory();
 let logBookDetailsBag = {};


 const pickSelectedTrip = () => {

        let organisationID = loginDetailsBag[0].id;

       let updateScheduledVehicle = {
                                     requisitionID,
                                     vehicleOwner,
                                     vehicleID,
                                     vehicleReg,
                                     fullname,
                                     cellnumber,
                                     Organisation :organisationID,
                                     vehicleNum,
                                     startTime,
                                     startFuel:'',
                                     startMileage,
                                     tripId
                                    }                              

        logBookDetailsBag = updateScheduledVehicle                            
        goToEditForm();                   


 }
 

 
 const goToEditForm = () => history.push({
  
  pathname:'editlog',
  editLogBookState:logBookDetailsBag

})


     if(loading){
 
        return <h2>Loading..</h2>
 
    }

  
    return (
        <>
     
     <Grid item xs={12} sm={12}>
                                
               <Accordion className={classes.accordion}>
                          <AccordionSummary expandIcon={<ArrowDropDownOutlinedIcon className={classes.accordionIcon} />} >
                            
                               Registration# : <span> {vehicleReg}</span>
                          </AccordionSummary>
                    
                          <AccordionDetails >
                           <span style={{borderTop: '1px solid rgba(0, 0, 0, 0.12)', width:'100%'}}> 
                            <p >
                                
                                 
                                  <b>Drivers Fullname :</b>{fullname}  
                                  <br/>

                                  <b>Drivers Cellnumber :</b>{cellnumber}
                                  <br/>

                                  <b> Departure Time :</b> {startTime}
                                  <br/>

                                  <b> Departure Mileage :</b> {startMileage} 
                                  <br/>

                                  <b> Destination :</b>{destination}

                                  <br/>

                                  <br/>

                                   <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>pickSelectedTrip()}>
                               <span className={classes.btnTxt}>Edit</span>
                             </Button> 
                            </p>
                           </span> 
                        </AccordionDetails>
              
                      </Accordion>  


                 
            </Grid>
   
 </> 

    )
}

export default LoggedVehicle;
