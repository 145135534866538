import React from 'react';
import { Grid} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import useStyles from '../../Requisition/styles';	
import { useHistory } from 'react-router-dom';
    
   
export const VehiclesInTransit = ({vehicleRegistration, vehicleDriver, driversContact, destination, departure, endTime}) => {


 const classes = useStyles(); 


	return (

    <>
     
     <Grid item xs={12} sm={12}>
             
                                
                    
               <Accordion className={classes.accordion}>
                          <AccordionSummary expandIcon={<ArrowDropDownOutlinedIcon className={classes.accordionIcon} />} >
                            
                               Registration # :<span>{vehicleRegistration}</span>
                          </AccordionSummary>
                    
                          <AccordionDetails >
                           <span style={{borderTop: '1px solid rgba(0, 0, 0, 0.12)', width:'100%'}}> 
                            <p >
                                   
                                  
                                  <b>Drivers Fullname :</b>{vehicleDriver}

                                  <br/>

                                  <b>Drivers Contact :</b>{driversContact}

                                  <br/>

                                  <b>Destination :</b>{destination}

                                  <br/>

                                  <b>Departure :</b>{departure}

                                  <br/>

                                  <b>Estimated return :</b>{endTime}

                                  <br/>

                                                                     
                                </p>
                              </span> 
                                                          
                          </AccordionDetails>
              
                      </Accordion>  

               </Grid>
   
 </> 

	);
}

export default VehiclesInTransit;