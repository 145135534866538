import React, {useEffect, useState, useContext } from 'react';
import {Card,  CardContent,  Typography, Grid, Button} from '@material-ui/core';
import useStyles from '../Requisition/styles';
import { CardHeader,TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {CartContext} from '../../Context/CartContext';



function goToTop(){ 

   window.scrollTo(0, 0); 
   

}



export const RefillFuelApproval = () => {
	   

       const classes = useStyles();
       const history = useHistory();
       const { baseUrlContext, loginDetailsBag }= useContext(CartContext);
       const [loginDetails, setLoginDetails] = loginDetailsBag;
       const [employeeRefillCode, setEmployeeRefillCode ] = useState('');



   useEffect(()=> {

       goToTop();
       
}, [])

      
    
  const goToHome = () => history.push({
    
        pathname: '/individualrefill'

});

 
  const goToOrganization = () => history.push({
    
        pathname: '/organisationrefill'

});

const goToRefillTransactionsHistory = () => history.push({
    
        pathname: '/organisationfuelrefill' 

});

  
	return (
    <main className = {classes.content}> 
            <div className={classes.toolbar}/>

       <Grid container direction="column" className={classes.root}>
	
            <Grid item></Grid>

                <Grid item container spacing={4}>

                  <Grid item xs={false} sm={2} md={3} lg={4}/>
                      <Grid item xs={12} sm={8} md={6} lg={4} >
                            
                      <Card >
                        <CardContent>

                          <CardHeader title="Fuel Refill"  align="center" />

                            <Button className={classes.cardButton}
                                         type="button" variant="contained" fullWidth color="inherit" onClick={goToHome}>
                                         <span className={classes.btnTxt}>Organization Refill</span>
                            </Button> 

                            <Button className={classes.cardButton}
                                         type="button" variant="contained" fullWidth color="inherit" onClick={goToOrganization}>
                                         <span className={classes.btnTxt}>Personal Refill</span>
                            </Button>

                             {/*<Button className={classes.cardButton}
                                         type="button" variant="contained" fullWidth color="inherit" onClick={goToRefillTransactionsHistory}>
                                         <span className={classes.btnTxt}>Fuel Refill Transactions History</span>
                            </Button>*/}
             
                    </CardContent>
               </Card>
                          
                        </Grid>
                    <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>
              </Grid>

 </main>
	);
}

export default RefillFuelApproval;