import React, {useState, useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, InputAdornment, Typography, Button, MenuItem } from '@material-ui/core';
import Pagination from '../../components/Pagination/Pagination';
//import Cart from '../../components/Cart/Cart';
import useStyles from '../Requisition/styles';
import axios from 'axios';
import {CartContext} from '../../Context/CartContext';
import { useHistory, Link } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';


  function Maintenance(){
    
    const classes = useStyles();
    const history = useHistory();
    const[products, setProducts] = useState([]);
    const[loading, setLoading] = useState(false); 
    const[currentPage, setCurrentPage] = useState(1);
    const[productsPerPage, setProductsPerPage] = useState(100);
    const[search, setSearch] = useState('');
    const [loginError, setLoginError] = useState('');
    const {loginDetailsBag, selectedLocationBag, baseUrlContext, userLastInteractionBag, selectedMusicCategoryBag }= useContext(CartContext); 
    const [selectedMusicCategoryID, setSelectedMusicCategoryID] = selectedMusicCategoryBag;
    const [loginDetails, setLoginDetails] = loginDetailsBag;
    const [selectedLocationID, setSelectedLocationID] = selectedLocationBag;
    const [userLastInteraction, setUserLastInteraction] = userLastInteractionBag;
    const[callServer, setCallServer] = useState('');
    const baseUrl = baseUrlContext;
    const [open, setOpen] = React.useState(false);  
    let currentState = userLastInteraction;





    const availableVehicle = [{id:1, name:'VSD 123 BH'},
                        {id:2,name:'BSD 123 OS'}];
    let selectedVehicle = '';                    

    const [vehicleValue, setVehicleValue] = React.useState([]);                    


    const availableOrganisation = [{id:1, name:'Individual'},
                                   {id:2,name:'ePayNet'},
                                   {id:3,name:'SalvTec'}, 
                                   {id:3,name:'Digimage'}];
    let selectedOrganisation = '';                    

    const [organisationValue, setOrganisationValue] = React.useState([]);                    


    
    if(userLastInteraction === 'musicCategory'){
     currentState = 'musicCategory'+ selectedMusicCategoryID;
    }else if(userLastInteraction === 'location'){
      currentState = 'location'+  selectedLocationID;
    }

    if(currentState !== callServer){

       setCallServer(currentState);
       
    }



   useEffect(()=> {

      window.scrollTo(0, 0);

      
      const fetchProducts = async()=>{
          setLoading(true);
    let res = '';
      if(selectedMusicCategoryID !==0 && userLastInteraction === 'musicCategory'){
         res = await axios.get(baseUrl + 'bridgeBringMusicCategoryHotSongs.php?categoryID='+selectedMusicCategoryID);

         }else if(userLastInteraction === 'location'){
            res = await axios.get(baseUrl + 'bridgeBringLocationHotSongs.php?locationID='+selectedLocationID);
         }
         else
         {
            res = await axios.get(baseUrl +'bridgeBringHotSongs.php');
         }

           res.data = JSON.parse(atob((res.data).slice(0,-2).split("").reverse().join("") + (res.data).slice(-2)));
          setProducts(res.data);
          setLoading(false);
      }

      fetchProducts();
      
   }, [callServer])




   // GET LOGIN DETAILS FROM THE PREVIOUS PAGE

         const checkObjType = history.location.loginState;

        if (typeof checkObjType  !== 'undefined' && Object.keys(history.location.loginState).length > 0) {
        
          setLoginDetails(history.location.loginState);
         history.push({pathname: '/songs', state: {} });
         
   
   
      }


   // GET CURRENT PRODUCTS

   const indexOfLastProduct = currentPage * productsPerPage;
   const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
   let currentProducts = products.slice(indexOfFirstProduct, indexOfLastProduct);

   //Change Page
   const paginate = pageNumber => setCurrentPage(pageNumber);
   let filteredProducts = [];
   if(search != ''){

         filteredProducts = products.filter(product => {

        return product.stageName.toLowerCase().includes(search.toLowerCase());
    });
   
   }


const handleVehicleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setVehicleValue(event.target.value);
          selectedVehicle = event.target.value;
         
  
   }; 

   const handleOrganisationChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setOrganisationValue(event.target.value);
          selectedOrganisation = event.target.value;
         
  
   }; 



const handleSubmit = () => {
  

}
   

   if(filteredProducts.length > 1){
        
          currentProducts =   filteredProducts;
   }


   return (
    <main className = {classes.content}>
            <div className={classes.toolbar}/>

   
  <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                        
                         <Grid item xs={false} sm={2} md={3} lg={4}/>   
                        <Grid item xs={12} sm={8} md={6} lg={4}>
                            <Card >
                            
                            <Backdrop className={classes.backdrop} open={open} >
                                 <Loader/>
                            </Backdrop>


                      <CardHeader title="Requisit Vehicle"  align="center" />
                    <CardContent>
                                    <div style={{color:"red", align:"center"}}>{loginError}</div>
                            
                             <Typography variant="h4" gutterBottom>                
                               <TextField label="Select Employer Registration Number" variant="outlined"  fullWidth size="small" select
                                 onChange={handleOrganisationChange}
                                 value={organisationValue} >

                                    {availableOrganisation.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.name} 
                                   </MenuItem>
                                   ))}
                               </TextField>
                              </Typography>
                                  
                            <Typography variant="h4" gutterBottom>                
                               <TextField label="Select Vehicle Registration Number" variant="outlined"  fullWidth size="small" select
                                 onChange={handleVehicleChange}
                                 value={vehicleValue} >

                                    {availableVehicle.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.name} 
                                   </MenuItem>
                                   ))}
                               </TextField>
                              </Typography>


                           


                              <Typography variant="h4" gutterBottom>
                                     <TextField label="Mileage" variant="outlined"  fullWidth size="small" placeholder=""  
                                        type="text"
                                       id="employeeID"
                                       name="employeeID"
                                      />
                                    </Typography>


                              <Typography variant="h4" gutterBottom>
                                     <TextField label="Service Provider" variant="outlined"  fullWidth size="small" placeholder="GM Motors"  
                                        type="text"
                                       id="serviceProvider"
                                       name="serviceProvider"
                                      />
                                    </Typography>      



                              <Typography variant="h4" gutterBottom>
                                  <TextField label="Service Details" variant="outlined"  fullWidth size="small"  placeholder="Dvokolwako"
                                 id="destination"
                                 name="destination"                 
                                 />
                               </Typography>

                               <Typography variant="h4" gutterBottom>
                                  <TextField label="Next Service Mileage" variant="outlined"  fullWidth size="small"  placeholder=""
                                 id="destination"
                                 name="destination"                 
                                 />
                               </Typography>


                               
                                 
                         
                          <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                          </Backdrop>            


                         <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleSubmit()}>
                               <span className={classes.btnTxt}>Submit</span>
                          </Button>

                        
     
                   
     
                    </CardContent>
                  </Card>
              </Grid>
          
                 
                   <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>

              </Grid>

     </main>
            

    );
   
}
export default Maintenance;