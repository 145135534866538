import React,{useEffect, useState, useContext} from 'react';
import {Card,  CardContent,  Typography, Grid, Button } from '@material-ui/core';
import useStyles from '../Requisition/styles';
import { CardHeader,TextField } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import {CartContext} from '../../Context/CartContext';
import { Transactions } from './Transactions/Transactions';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';


function goToTop(){ 

   window.scrollTo(0, 0); 
   

}



export const RefillFuelResponse = () => {
	    

       const classes = useStyles(); 
       const history = useHistory();
       const { baseUrlContext, loginDetailsBag } = useContext(CartContext);
       const baseUrl = baseUrlContext;
       const [open, setOpen] = React.useState(false);

       const refillTransactionDetail = history.location.refillTransactionState;
       const transactionDetailsBag = history.location.transactionDetailsState;

       const [responseMessage, setResponseMessage] = useState('');
       const [success, setSuccess] = useState(false); 
       const [error, setError] = useState(false); 
       const [loading, setLoading] = useState(false); 
       let transactionResponseDetailsBag = {};   
        

   useEffect(()=> {

       setOpen(!open);
       goToTop();
       setOpen(false);
       
}, [])


  const goToHome = () => history.push({
    
        pathname: '/dashboard' 

});

 const goToTransactionStatus = () => history.push({
    
        pathname: '/completefuelrefilltransaction',
        transactionState:transactionResponseDetailsBag 

});

 
const approveTransaction = async(id)=>{
   
   setOpen(!open);
   let data = {}; 

  if(transactionDetailsBag !== ''){

   //INDIVIDUAL TRANSACTION
   

    data = { 
              
                amount:transactionDetailsBag.amount,
                litres:transactionDetailsBag.quantity, 
                vehicleNumber: transactionDetailsBag.vehicleRegNum,
                mileage: transactionDetailsBag.mileage,
                fuelTypeID:transactionDetailsBag.fuelType,
                fuelID:transactionDetailsBag.fuelName,
                fuelStationID:refillTransactionDetail.id,
                companyID:loginDetailsBag[0].id  

              };
  
  }

  if(transactionDetailsBag === ''){

    //ORGANIZATION TRANSACTION
     console.log('ORGANIZATION TRANSACTION');


       data = { 
              
                    amount:'test', 
                    litres:'test', 
                    vehicleNumber: 'test', 
                    mileage: 'test',
                    fuelTypeID:'test', 
                    fuelID:'test', 
                    fuelStationID:'test', 
                    companyID:loginDetailsBag[0].id  
              
                  };
  
  }  
  
  //data = JSON.stringify(data);


         
     const res = await axios.post(baseUrl +'fuelFillingTransaction.php', data )
     .then(function (response) {

 

      if(response.data.code === '200'){
              
              transactionResponseDetailsBag = response.data;
              setSuccess(true);
              setResponseMessage(response.data);
              setOpen(false);    
              goToTransactionStatus()
            
                    
      }else{
            
            setSuccess(true);
            setResponseMessage(response.data);

          setTimeout(() => {              
            
          },5000)

     }
  });
  
  
}


if(loading){

        return  (
          
           <Loader/>
         
        )
    }



	return (
    <main className = {classes.content}> 
            <div className={classes.toolbar}/>

       <Grid container direction="column" className={classes.root}>
	
            <Grid item></Grid>

                <Grid item container spacing={4}>

                 <Grid item xs={false} sm={2} md={3} lg={4}/>
                   <Grid item xs={12} sm={8} md={6} lg={4} >
                    <Card >
                        <br/>    
                       
                       
                            

                           <Typography variant="h6" gutterBottom align="center">                
                             Filling Station Name :<strong>{refillTransactionDetail.stationName}</strong>
                          </Typography>
                           <hr></hr>
                                  
                     
                                                                       
                        <CardContent>
                          <span style={{borderTop: '1px solid rgba(0, 0, 0, 0.12)', width:'100%'}}> 
                            <p>
                                                                    
                               <Typography variant="body2" gutterBottom >    
                                  Cell Number : <b>{refillTransactionDetail.momoAccountNumber}</b>
                                 
                               </Typography>   

                               <Typography variant="body2" gutterBottom > 
                                  Retail Code : <b>{refillTransactionDetail.retailCode}</b>
                               </Typography>

                            {transactionDetailsBag ?     

                              <>
                               <Typography variant="body2" gutterBottom > 
                                  Amount : <b>E{transactionDetailsBag.amount} </b>
                               </Typography>


                               <Typography variant="body2" gutterBottom > 
                                  Fuel Quantity : <b>{transactionDetailsBag.quantity}</b> Litre(s)
                               </Typography>


                               <Typography variant="body2" gutterBottom > 
                                  Vehicle Registration# : <b>{transactionDetailsBag.vehicleRegNum}</b>
                               </Typography>


                               <Typography variant="body2" gutterBottom > 
                                  Liquid Definition : <b> {transactionDetailsBag.selectedFuelName} </b>
                               </Typography>
                              </> 

                              :

                              <>
                              </> 

                            }  
                            </p>
                          </span>
                                            
                            <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                            </Backdrop>               

                            <Button className={classes.cardButton}
                               type="button" variant="contained" fullWidth color="inherit" onClick={approveTransaction}>
                                      <span className={classes.btnTxt}>Approve</span>
                            </Button>  
                        </CardContent>
                      </Card>                  
                    </Grid>
                 <Grid item xs={false} sm={2} md={3} lg={4}/>
               </Grid>
          </Grid>

 </main>
	);
}

export default RefillFuelResponse;