import React,{useEffect, useState, useContext} from 'react';
import {Card,  CardContent,  Typography, Grid, Button } from '@material-ui/core';
import useStyles from '../Requisition/styles';
import { CardHeader,TextField } from '@material-ui/core';
import {CartContext} from '../../Context/CartContext';
import { Transactions } from './Transactions/Transactions';
import { useHistory } from 'react-router-dom';
import axios from 'axios';




function goToTop(){ 

   window.scrollTo(0, 0); 
   

}



export const RefillFuelFSResponse = () => {
	   

       const classes = useStyles(); 
       const history = useHistory();
       const { baseUrlContext, loginDetailsBag } = useContext(CartContext);
       const baseUrl = baseUrlContext;
       const [open, setOpen] = React.useState(false);
       const refillTransactionDetail = history.location.refillTransactionState;
       const [responseMessage, setResponseMessage] = useState('');
       const [success, setSuccess] = useState(false); 
       const [error, setError] = useState(false);     
       
    
      
   useEffect(()=> {

       setOpen(!open);
       goToTop();
       setOpen(false);
       
}, [])


  const goToHome = () => history.push({
    
        pathname: '/dashboard' 

});

 
const approveTransaction = async(id)=>{
  
      
   setOpen(!open);
  
   let data = { transactionID:refillTransactionDetail.id };
    
      data = JSON.stringify(data);
         
     const res = await axios.post(baseUrl +'manualApproveFuelStationTransaction.php', data )
     .then(function (response) {

 
      if(response.data.code === '200'){
           
          
          setSuccess(true);
          setResponseMessage(response.data);
          setOpen(false);

          setTimeout(() => {

                setSuccess(!true);
                         
          },5000)
              
      
      }else{

       
          setError(true);
          setResponseMessage(response.data);
          setOpen(false);

          setTimeout(() => {

                setError(!true);
            
          },5000)

               
      }
  });
}


	return (
    <main className = {classes.content}> 
            <div className={classes.toolbar}/>

       <Grid container direction="column" className={classes.root}>
	
            <Grid item></Grid>

                <Grid item container spacing={4}>

                  <Grid item xs={false} sm={2} md={3} lg={4}/>
                      <Grid item xs={12} sm={8} md={6} lg={4} >
                        <Card >
                            <CardHeader title="Transaction Status" align="center" />
                           <CardContent>
                              <Typography variant="body2" gutterBottom >                
                                <b>{refillTransactionDetail.description}     </b>
                              </Typography>
                           </CardContent>
                           
       
                        </Card>  
                          
                        </Grid>
                    <Grid item xs={false} sm={2} md={3} lg={4}/>
                 </Grid>
              </Grid>

 </main>
	);
}

export default RefillFuelFSResponse;