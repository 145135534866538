import React, {useContext, useState} from 'react';
import {Card, CardMedia, Button, CardContent, CardActions, Typography, IconButton, Grid} from '@material-ui/core';
import { AddShoppingCart, Headset as PlayIcon, Person as ArtistIcon, PausePresentation as PauseIcon, ThumbUpTwoTone as LikeIcon, FavoriteBorderTwoTone as HeartIcon, ThumbDownTwoTone as DislikeIcon } from '@material-ui/icons';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import AddIcon from '@material-ui/icons/Add';
import useStyles from '../../Requisition/styles'; 
import {CartContext} from '../../../Context/CartContext';





export const LogHistory = ({vehicleOwner, vehicleID, tripId, vehicleNum, vehicleReg, startTime, startMileage, startFuel, destination, date}) => {

 const classes = useStyles(); 

 const { loginDetailsBag, cartBag }= useContext(CartContext);
 const [loginDetails, setLoginDetails] = loginDetailsBag;
 const[loading, setLoading] = useState(false); 





     if(loading){
 
        return <h2>Loading..</h2>
 
    }

  
    return (
        <>
     
        
     
     <Grid item xs={12} sm={12}>
                                
               <Accordion className={classes.accordion}>
                          <AccordionSummary expandIcon={<ArrowDropDownOutlinedIcon className={classes.accordionIcon} />} >
                            
                               Registration# : <span> {vehicleReg}</span>
                          </AccordionSummary>
                    
                          <AccordionDetails >
                           <span style={{borderTop: '1px solid rgba(0, 0, 0, 0.12)', width:'100%'}}> 
                            <p >
                              
                                  <b> Vehicle Number:</b> {vehicleNum}

                                  <br/>  

                                  <b> Departure Time :</b> {startTime}

                                  <br/>

                                  <b> Departure Mileage :</b> {startMileage} 

                                  <br/>

                                   {/*<b> Departure Fuel :</b> {startFuel} 

                                  <br/>*/}

                                   <b> Destination :</b>{destination}

                                  <br/>

                                  <br/>

                                    
                            </p>
                           </span> 
                        </AccordionDetails>
              
                      </Accordion>  


                 
            </Grid>
   
 </> 

    )
}

export default LogHistory;
