import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
//import DeleteIcon from '@mui/icons-material/Delete';
//import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import {CartContext} from '../../../Context/CartContext';
import React, {useEffect, useContext, useState} from 'react';
import { Grid} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import useStyles from '../../Requisition/styles'; 
import { useHistory } from 'react-router-dom';
import axios from 'axios';
   
   

export const TestTable = ({vehicleDetails,vehicleReg, vehicleNum}) => {

   const { baseUrlContext, loginDetailsBag }= useContext(CartContext);
  const baseUrl = baseUrlContext;
   const [open, setOpen] = React.useState(false);
      const [addedVehicles, setAddedVehicles] = useState([]);
      let rows = [];
     


const columns = [
  { id: 'transaction', label: 'Transaction #', minWidth: 170 },
  { id: 'destination', label: 'destination', minWidth: 100 },
  {
    id: 'mileage',
    label: 'Mileage',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'purpose',
    label: 'Purpose',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'registration',
    label: 'Registration #',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];


  useEffect(()=> {
          
         let data = { selectedCompanyID:'1' };  

       const res = axios.post(baseUrl+'getVehicles.php',data ).then(function (response) {
     
     
      //   availableVehiclesDetail = response.data;
         setAddedVehicles(response.data);
        setOpen(false);

        });


   }, [])






function createData(transaction, destination, mileage , purpose, registration) {
  

   //  for (let i = 0; i < vehicleDetails.length; i++) { 

  return { transaction:vehicleDetails.transactionNumber, destination:vehicleDetails.companyName, mileage:vehicleDetails.id , purpose:vehicleDetails.id, registration:vehicleDetails.id };

  //   }

}


//for (let i = 0; i < vehicleDetails.length; i++) { 

 rows = [



       createData(vehicleDetails),
       createData(vehicleDetails),
       createData(vehicleDetails),
       createData(vehicleDetails),
       createData(vehicleDetails),
       createData(vehicleDetails),
       createData(vehicleDetails),
       createData(vehicleDetails),
       createData(vehicleDetails),
       createData(vehicleDetails),
       createData(vehicleDetails),
       createData(vehicleDetails),
       createData(vehicleDetails),
  


  
];
//  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table"> 
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
);
}