import React, {useState, useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, InputAdornment, Typography, Button, MenuItem } from '@material-ui/core';
import Requisited from '../Requisition/Requisition';
import Pagination from '../../components/Pagination/Pagination';
import useStyles from '../Requisition/styles';
import axios from 'axios';
import {CartContext} from '../../Context/CartContext';
import { useHistory, Link } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';
import {LoggedVehicle} from './LoggedVehicle/LoggedVehicle';
import {LogHistory} from './PrevioslyUsed/LogHistory';
import{AlertSuccess} from '../Alert/AlertSuccess';


  function LogBook(){
    
    const classes = useStyles();
    const history = useHistory();
    const[products, setProducts] = useState([]);
    const[loading, setLoading] = useState(false); 
    const [loginError, setLoginError] = useState('');
    const {loginDetailsBag, selectedLocationBag, baseUrlContext, userLastInteractionBag, selectedMusicCategoryBag }= useContext(CartContext); 
    const [selectedMusicCategoryID, setSelectedMusicCategoryID] = selectedMusicCategoryBag;
    const [loginDetails, setLoginDetails] = loginDetailsBag;
    const baseUrl = baseUrlContext;
    const [open, setOpen] = React.useState(false);  
    const [addedVehicle, setAddedVehicle ] = useState([]);
    const [scheduledTrips, setScheduledTrips] = useState([]);
    const [myHistory, setMyHistory] = useState([]);
    const [vehicleValue, setVehicleValue] = useState('');
    const [startTime, setStartTime] = useState('');
    const [startMileage, setStartMileage] = useState('');
    const [startFuel, setStartFuel] = useState('');
    const [destination, setDestination] = useState('');
    const [employers, setEmployers] = useState([]);
    const [selectedEmployer, setSelectedEmployer] = useState('');      
    const [employeeNumber, setEmployeeNumber] = useState(''); 
    const [vehicleID, setVehicleID] = useState('');
    const [logedCompanies, setLogedCompanies] = useState([]);
    const [selectedLogedVehicle, setSelectedLogedVehicle] = useState('');
    const [companiesWithHistory, setCompaniesWithHistory] = useState([]);
    const [selectedHistoryCompany, setSelectedHistoryCompany] = useState('');
    const [requisitionID, setReqiusitionID] = useState('');
    const [selectedRequisitionID, setSelectedRequisitionID] = useState('');
    let selectedVehicle = '';                                    
    let logBookData = {};          
    let selectedEmployersID = '';     
    const [responseMessage, setResponseMessage] = useState('');
    const [success, setSuccess] = useState(false);     
    
  

  useEffect(()=> {

       window.scrollTo(0, 0); 
       setOpen(!open); 
       getEmployers();


   }, [])


    const getEmployers = async()=>{

        let nationalID = loginDetailsBag[0].nationalID;
        let data = { nationalID };
     
       const res = axios.post(baseUrl+'getMyEmployers.php',data ).then(function (response) {
     
       setEmployers(response.data);
       setLogedCompanies(response.data);
       setCompaniesWithHistory(response.data);
       setOpen(false); 

        });

 }  
 


const handleVehicleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            

    setVehicleValue(event.target.value);
    let myVehiclesID = event.target.value;
   
       for (let i = 0; i < addedVehicle.length; i++) { 


              let matchingVehicle = addedVehicle[i].id;

           if(myVehiclesID == matchingVehicle){
                           
          
                let requisitionID = addedVehicle[i].requisitions_id;
                setSelectedRequisitionID(requisitionID);
               
           }  

       }
    

}



const handleEmployerChange  = (event: React.ChangeEvent<HTMLInputElement>) =>{
  
    setSelectedEmployer(event.target.value);
    let selectedEmployersIdentity = event.target.value;
  
    setOpen(!open);
  
    let data = { selectedCompanyID:selectedEmployersIdentity };
   
   const res = axios.post(baseUrl+'getRequisitedVehicles.php',data ).then(function (response) {
          
         
        setAddedVehicle(response.data);
        setOpen(false);

        });

   for (let i = 0; i < employers.length; i++) { 

        let employersID = employers[i].id;

        if(employersID == selectedEmployersIdentity){
          
          
          let employee = employers[i].employeeNumber
          setEmployeeNumber(employee)

     }        
   }  

}



const loadHistoryData  = (event: React.ChangeEvent<HTMLInputElement>) =>{
  
    let MySelectedCompanyHistoryID = '';
    setSelectedHistoryCompany(event.target.value);
     MySelectedCompanyHistoryID = event.target.value;
  
    setOpen(!open);
  
  
    let data = { companyID:MySelectedCompanyHistoryID };
     
       const resp = axios.post(baseUrl+'getTrips.php',data ).then(function (response) {
    
       setOpen(false);
       
       setMyHistory(response.data);       
      
      }); 

}



 const handleComanyTripChange  = (event: React.ChangeEvent<HTMLInputElement>) =>{
      
       setOpen(!open);
       let selectedEmployersIdentity = '';
       setSelectedLogedVehicle(event.target.value);
       selectedEmployersIdentity = event.target.value;
       let myEmployee = '';

       
    for (let i = 0; i < employers.length; i++) { 

        let employersID = employers[i].id;

        if(employersID == selectedEmployersIdentity){

          let employee = employers[i].employees_id;
          myEmployee = employee;
          setEmployeeNumber(employee);
          

        }

   }
   

      
  
       let data = { companyID:selectedEmployersIdentity,employeeID:myEmployee };
   
       const res = axios.post(baseUrl+'getMyVehicleInTransit.php',data ).then(function (response) {
    
       setOpen(false);      
       setScheduledTrips(response.data);

       
    });      

}




const handleSubmit = async()=>{
        
   setOpen(!open);

   let organisationID = loginDetailsBag[0].id;  
   let startFuel = '';
  

   let data = {requisitionID:selectedRequisitionID, vehicleOwnercompanyID:selectedEmployer, appUserCompanyID:organisationID, vehicleID:vehicleValue, employeeNumber, startTime, startMileage, startFuel, destination  };
    
     data = JSON.stringify(data);
         
     const res = await axios.post(baseUrl +'startTrip.php', data )
     .then(function (response) {

      if(response.data.code === '200'){
           
              
                setSuccess(true);
                setResponseMessage(response.data);
                setOpen(false);

              setTimeout(() => {

                    setSuccess(!true);
                    goToDashboard();
                
              },2000)

                    
      }else{

           setSuccess(true);
              setResponseMessage(response.data);
              setOpen(false);

              setTimeout(() => {

                    setSuccess(!true);
                
              },8000)
      }
         
  });

}


const goToDashboard = () => history.push({
  
  pathname:'dashboard'

})



    return (
           <main className = {classes.content}>
            <div className={classes.toolbar}/>

   
  <Grid container direction="column">

                  <Grid item></Grid>

                <Grid item container spacing={4}>
                        
                    <Grid item xs={12} sm={3} md={4} lg={4}>

                        <Grid container spacing={2}>
                           

                    <Card style={{width:'100%'}}>
                         <CardHeader title="History" align="center"></CardHeader>
                        <CardContent >           


                    <Typography variant="body2" gutterBottom >                
                      <TextField label="Select History Company" variant="outlined" fullWidth size="small" select
                                  onChange={loadHistoryData}
                                  value={selectedHistoryCompany}
                                  > 

                                {companiesWithHistory.map((val) => (
                                 
                                   <MenuItem key={val.id} value={val.id} >
                                       {val.companyName} 
                                   </MenuItem>

                                ))}
                              </TextField>
                 </Typography>
                                
                                {myHistory.map((history)=> (    
                                  <>
                                     <LogHistory

                                        vehicleOwner={history.companies_id}
                                          vehicleID={history.vehicleNumber}
                                          vehicleReg={history.registrationNumber}
                                          startTime = {history.startTime}
                                          startMileage = {history.startMileage}
                                          startFuel = {history.fuelStart}
                                          destination = {history.destination}
                                          date = {history.created_at}
                                          tripId = {history.trips_id}
                                          vehicleNum = {history.vehicleNumber}
                                          requisitionID= {history.requisistions_id}
                                          key={history.trips_id}

                                     />

                                     <br/>
                                   </>  



                                   ))}       


                                   </CardContent>    
                                  </Card>     

                              </Grid>

                         </Grid>   
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Card >
                              

                            { success  ?
                          
                                    <>
                                      <AlertSuccess messsage={responseMessage.description} code={responseMessage.code}/> 

                                    </> 
                                      :  
                                        <div >
                                        </div>
                    
                          }
                              
                            <Backdrop className={classes.backdrop} open={open} >
                                 <Loader/>
                            </Backdrop>

 
                 <CardHeader title="Log Book"  align="center" />
                    <CardContent>
                            <div style={{color:"red", align:"center"}}>{loginError}</div>
                          
                             <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Employer" variant="outlined" fullWidth size="small" select
                                  onChange={handleEmployerChange}
                                  value={selectedEmployer}
                                  > 

                                    {employers.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.companyName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography> 

                                  
                           

                            <Typography variant="h4" gutterBottom>                
                               <TextField label="Select Vehicle" variant="outlined"  fullWidth size="small" select
                                 onChange={handleVehicleChange}
                                 value={vehicleValue} >

                                    {addedVehicle.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.registrationNumber} 
                                   </MenuItem>
                                   ))}
                               </TextField>
                              </Typography>


                            
                               <Typography variant="h4" gutterBottom >
                                   <TextField label="Start Time" variant="outlined" fullWidth size="small"   
                                   type="datetime-local"
                                   id="startTime"
                                   name="startTime"
                                   value={startTime}
                                   onFocus={(e) => (e.currentTarget.type = "datetime-local")}
                                   onBlur={(e) => (e.currentTarget.type = "text")}
                                   onChange={(e)=>setStartTime(e.target.value)}
                                      />
                                   
                               </Typography>


                                 <Typography variant="h4" gutterBottom >
                                   <TextField label="Start Mileage" variant="outlined" fullWidth size="small"   
                                   type="text"
                                   id="startMileage"
                                   name="startMileage"
                                   value={startMileage}
                                   onChange={(e)=>setStartMileage(e.target.value)}
                                      />
                                   
                               </Typography>

                               <Typography variant="h4" gutterBottom>
                                  <TextField label="Destination" variant="outlined"  fullWidth size="small"  placeholder="Dvokolwako"
                                 id="destination"
                                 name="destination" 
                                 value={destination} 
                                 onChange={(e)=>setDestination(e.target.value)}               
                                 />
                               </Typography>
     
                         
                          <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                          </Backdrop>            


                         <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleSubmit()}>
                               <span className={classes.btnTxt}>Submit</span>
                          </Button>

                        
     
                   
     
                    </CardContent>
                  </Card>
              </Grid>
          
                 
                   <Grid item xs={12} sm={3} md={4} lg={4}>
                      <Grid container spacing={2}>

                     <Card style={{width:'100%'}}>
                        <CardHeader title="Vehicle In Transit"  align="center" />
                        <CardContent >

                          <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Employer" variant="outlined" fullWidth size="small" select
                                  onChange={handleComanyTripChange}
                                  value={selectedLogedVehicle}
                                  > 

                                    {logedCompanies.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.companyName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>

                                 {scheduledTrips.map((schedule)=> ( 

                                  <>
                                     <LoggedVehicle  

                                          vehicleOwner={schedule.companies_id}
                                          vehicleID={schedule.vehicles_id}
                                          vehicleReg={schedule.registrationNumber}
                                          startTime = {schedule.startTime}
                                          startMileage = {schedule.startMileage}
                                          startFuel = {schedule.fuelStart}
                                          fullname = {schedule.fullName}
                                          cellnumber = {schedule.cellNumber}
                                          destination = {schedule.destination}
                                          date = {schedule.created_at}
                                          tripId = {schedule.trips_id}
                                          vehicleNum = {schedule.vehicleNumber}
                                          key={schedule.trips_id}
                                          
                                     />
                                      <br/>
                                   </> 
                                
                                 ))} 
                                 <br/>
                            

                            </CardContent>    
                          </Card>     
                        <br/><br/>
                      </Grid>
                   </Grid>
                 </Grid>

              </Grid>

     </main>
            
    );
   
}
export default LogBook;