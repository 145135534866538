import React from 'react';
import {MasterPage, Requisition, NavBar, Footer, Dashboard, RefillFuel, Maintenance, Recharge, PasswordReset, PasswordResetResponse, PasswordResetStatus, Login, Registration, VehicleEnrollment, LogBook, EditLog, RefillFuelResponse, RefillFuelApproval, UserProfile, OrganisationRefill, RefillFuelFSResponse, IndividualRefill, RefillHistory, OrganisationFuelRefill, VehicleList, CompleteFuelRefillTransaction, RefillFuelOrganisationResponse, HistoryTest } from './components';
import {CartProvider} from './Context/CartContext';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import useStyles from './components/Requisition/styles';



const App = () => {

	const classes = useStyles();

    return (
    	<Router basename="/">
	    	<CartProvider>
		        <div className={classes.mainWrapper}>
		            <NavBar />
		              <Switch> 

		                    <Route exact path = "/masterpage">
		             		   <MasterPage />
		             		</Route>

		             		<Route exact path = "/">
		             		   <Login />
		             		</Route> 

		             		<Route exact path = "/requisition">
		             		   <Requisition />
		             		</Route>

		             		<Route exact path = "/maintenance">
		             			 <Maintenance/>
		             		</Route>

		             		<Route exact path = "/dashboard">
		             		   <Dashboard />
		             		</Route>

		             		<Route exact path = "/registration">
		             		   <Registration />
		             		</Route>

		             		<Route exact path="/userprofile">
		             		   <UserProfile />	
		             		</Route>
		             		
		             		<Route exact path = "/logbook">
		             		   <LogBook />
		             		</Route>

		             		<Route exact path = "/editlog">
		             		   <EditLog />
		             		</Route>
			            	
		             		<Route exact path = "/refillfuel">
		             		   <RefillFuel />
		             		</Route>

		             		<Route exact path = "/refillfuelresponse">
		             		   <RefillFuelResponse />
		             		</Route>

		             		<Route exact path = "/refillfuelorganisationresponse">
		             				<RefillFuelOrganisationResponse	/>
		             		</Route>
		             				
		             		<Route exact path = "/refillfuelfsresponse">
		             		   <RefillFuelFSResponse />
		             		</Route>

		             		<Route exact path = "/refillfuelapproval">
		             				<RefillFuelApproval />		             		</Route>
		             		
		             		
		             		<Route exact path = "/completefuelrefilltransaction">
		             				<CompleteFuelRefillTransaction />
		             		</Route>		

		             		<Route exact path = "/recharge">
		             		   <Recharge />
		             		</Route>		             			

		             		<Route exact path = "/vehicleenrollment">
		             		   <VehicleEnrollment />
		             		</Route>
		             			
		             		<Route exact path = "/organisationrefill">
		             		   <OrganisationRefill />
		             		</Route>

		             		<Route exact path = "/individualrefill">
		             		   <IndividualRefill />
		             		</Route>

		             		<Route exact path = "/refillhistory">
		             		   <RefillHistory />
		             		</Route>


		             		<Route exact path = "/organisationfuelrefill">
		             		   <OrganisationFuelRefill />
		             		</Route>

		             		<Route exact path ="/vehiclelist">
		             			<VehicleList />
		             		</Route>
		             		
		             		
		             		<Route exact path = "/historytest">
		             		    <HistoryTest />	
		             		</Route>

		             		<Route exact path = "/passwordreset">
		             		   <PasswordReset />
		             		</Route>

		             		<Route exact path = "/passwordresetresponse">
		             		   <PasswordResetResponse />
		             		</Route>

		             		<Route exact path = "/passwordresetstatus">
		             		   <PasswordResetStatus />
		             		</Route>

		             		<Route exact path="/*">
							    <Login />
							</Route>
			           
		            </Switch>
		        </div>
		       <Footer/>    
	        </CartProvider>
	    </Router>
    ) 
}
export default App