import React, {useState, useEffect, useContext} from 'react';
import { Grid,  TextField, Card, CardContent, CardHeader, Typography, Button, MenuItem} from '@material-ui/core';
import Requisited from '../Requisition/Requisition';
import useStyles from '../Requisition/styles';
import axios from 'axios';
import {CartContext} from '../../Context/CartContext';
import { useHistory} from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../Loader/Loader';


function goToTop(){ 

   window.scrollTo(0, 0);
    
}

 
  function RefillFuel(){
    
    const classes = useStyles();
    const history = useHistory();
    const[loading, setLoading] = useState(false); 
    const [error, setError] = useState('');
    const {loginDetailsBag, baseUrlContext}= useContext(CartContext); 
    const [vehicleValue, setVehicleValue] = React.useState([]);                    
    const [organisationValue, setOrganisationValue] = React.useState([]);
    const [requisitedVehicles, setRequisitedVehicles] = useState([]);
    const [fuelQuantity,setFuelQuantity] = useState('');
    const [fuelType, setFuelType] = useState('');
    const [loginDetails, setLoginDetails] = loginDetailsBag;
    const baseUrl = baseUrlContext;
    const [open, setOpen] = React.useState(false);   
    const [companyID, setCompanyID] = useState('');   
    const [registrationNumber, setRegistrationNumber] = useState('');  
    const [liquidType, setliquidType] = useState('');      
    const [fuelName, setFuelName] = useState('');         
    const [amount, setAmount ] = useState('');
    const [quantity, setQuantity ] = useState('');
    const [employeeID, setEmployeeID ] = useState('');
    const [fuelTypes, setFuelTypes] = useState([]);
    const [availableFuels, setAvailableFuels] = useState([]);
    const [selectedFuel, setSelectedFuel] = useState('');
    const [mySelectedFuelType, setMySelectedFuelType] = useState('');
    const [mileage, setMileage] = useState('');
    let selectedFuelType = '';
    let selectedVehicle = ''; 
    let availableVehiclesDetail = '';  
    let selectedOrganisation = '';
    let refillTransactionDetail = {};                        

       

   useEffect(()=> {

       setOpen(!open);
       goToTop();
       let organisationID = loginDetailsBag[0].id;
       let data = { organisationID };
       
     
       const res = axios.post(baseUrl+'/fuelStation/getFuelTypes.php',data ).then(function (response) {

       
       setFuelTypes(response.data); 
       setOpen(false);    
       
       });

   }, [])


   const handleVehicleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setVehicleValue(event.target.value);
          selectedVehicle = event.target.value;

   }; 


   const handleFuelTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setMySelectedFuelType(event.target.value);
          selectedFuelType = event.target.value;
          let data = { fuelTypeID:selectedFuelType };

   
   const res = axios.post(baseUrl+'fuelStation/getFuels.php',data ).then(function (response) {
     
        setAvailableFuels(response.data);
        setOpen(false);

        });


          
   }; 


   const handleFuelChange = (event: React.ChangeEvent<HTMLInputElement>) => {

          setSelectedFuel(event.target.value);
  
   }; 



 const handleSubmit = async()=>{
        
   setOpen(!open);

   let fuelStationUserID = loginDetailsBag[0].id; 
   let fuelStationID = loginDetailsBag[0].fuelStations_id;
   let employeesID = companyID;

   let data = {companyRef:companyID, registrationNumber, fuelTypeID:mySelectedFuelType, fuelID:selectedFuel, liters:quantity, amount, employeeNumber:employeeID, fuelStationUserID, fuelStationID };
      
  
      data = JSON.stringify(data);
 
     const res = await axios.post(baseUrl +'fuelStation/fsTriggerFuelPayment.php', data )
     .then(function (response) {


      if(response.data.code == 200){
           
            
               refillTransactionDetail = response.data;
//               setSuccess(true);
//             setResponseMessage(response.data);
               setOpen(false);
               goToResponseStatus();
          
      
      }else{

         setError(response.data.error);
         setOpen(false);
      }

  }); 

}





 const goToResponseStatus = () => history.push({
  
  pathname:'refillfuelfsresponse',
  refillTransactionState:refillTransactionDetail

})


    return (
      <main className = {classes.content}>
            <div className={classes.toolbar}/>

              <Grid container direction="column">

                  <Grid item></Grid>

                     <Grid item container spacing={4}>
                            <Grid item xs={false} sm={2} md={3} lg={4}>      
                     </Grid>   
            
            <Grid item xs={12} sm={8} md={6} lg={4}>
              <Card >
                            
                      <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                      </Backdrop>

                  <CardHeader title="Refill Fuel"  align="center" />
                      
                      <Typography variant="body2" gutterBottom>
                      <div style={{color:"red", align:"center"}} align="center">{error}</div>
                      </Typography> 

                    <CardContent>
                  
                             
                            <Typography variant="h4" gutterBottom >
                                   <TextField label="Company Reference" placeholder="Company ID / Tin Number" variant="outlined" fullWidth size="small"   
                                   type="text"
                                   id="companyID"
                                   name="companyID"
                                   value={companyID}
                                   onChange={(e)=>setCompanyID(e.target.value)}
                                      />
                                   
                               </Typography>  

                               <Typography variant="h4" gutterBottom >
                                   <TextField label="Employee Identity" placeholder="Employee ID" variant="outlined" fullWidth size="small"   
                                   type="text"
                                   id="employeeID"
                                   name="employeeID"
                                   value={employeeID}
                                   onChange={(e)=>setEmployeeID(e.target.value)}
                                      />
                                   
                               </Typography>
                              
                              <Typography variant="h4" gutterBottom >
                                   <TextField label="Vehicle Registration Number" placeholder="ASD 000 HH" variant="outlined" fullWidth size="small"   
                                   type="text"
                                   id="registrationNumber"
                                   name="registrationNumber"
                                   value={registrationNumber}
                                   onChange={(e)=>setRegistrationNumber(e.target.value)}
                                      />
                                   
                               </Typography>


                               <Typography variant="h4" gutterBottom >
                                   <TextField label="Mileage" placeholder="" variant="outlined" fullWidth size="small"   
                                   type="text"
                                   id="mileage"
                                   name="mileage"
                                   value={mileage}
                                   onChange={(e)=>setMileage(e.target.value)}
                                      />
                                   
                               </Typography>



                          <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Liquid Type" variant="outlined" fullWidth size="small" select
                                  onChange={handleFuelTypeChange}
                                  value={mySelectedFuelType}
                                  > 

                                    {fuelTypes.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.typeName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>

                               
                               <Typography variant="h4" gutterBottom >                
                              <TextField label="Select Fuel" variant="outlined" fullWidth size="small" select
                                  onChange={handleFuelChange}
                                  value={selectedFuel}
                                  > 

                                    {availableFuels.map((val) => (
                                    <MenuItem key={val.id} value={val.id} >
                                       {val.fuelName} 
                                   </MenuItem>

                                   ))}
                              </TextField>
                          </Typography>

                                <Typography variant="h4" gutterBottom >
                                   <TextField label="Amount" placeholder="" variant="outlined" fullWidth size="small"   
                                   type="text"
                                   id="amount"
                                   name="amount"
                                   value={amount}
                                   onChange={(e)=>setAmount(e.target.value)}
                                      />
                                   
                               </Typography>

                               <Typography variant="h4" gutterBottom >
                                   <TextField label="Quantity" placeholder="" variant="outlined" fullWidth size="small"   
                                   type="text"
                                   id="quantity"
                                   name="quantity"
                                   value={quantity}
                                   onChange={(e)=>setQuantity(e.target.value)}
                                      />
                                   
                               </Typography>


                                 

                               
                                                                                   
                          <Backdrop className={classes.backdrop} open={open} >
                              <Loader/>
                          </Backdrop>            

                         <Button className={classes.cardButton} size="large" type="button" variant="contained" fullWidth onClick={()=>handleSubmit()}>
                               <span className={classes.btnTxt}>Submit</span>
                          </Button>         
     
                    </CardContent>
                  </Card>
              </Grid>
                           
              <Grid item xs={false} sm={2} md={3} lg={4}>
                      
            </Grid>
          </Grid>
        </Grid>

     </main>
            
    );
}
export default RefillFuel;